import { TermsAndConditionsContent } from "./components";
import { useTranslation } from "react-i18next";
import { LegalPageLayout } from "@/components/layouts";

function TermsAndConditions() {
  const { t } = useTranslation();
  return (
    <LegalPageLayout title={t("common_termsAndConditions")}>
      <TermsAndConditionsContent />
    </LegalPageLayout>
  );
}

export default TermsAndConditions;
