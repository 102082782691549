import { BannerRefContext } from "@/components/layouts";
import { useContext } from "react";
import { createPortal } from "react-dom";

export function LeftBannerPortal({ children }: { children: React.ReactNode }) {
  const bannerContentRef = useContext(BannerRefContext);

  if (!bannerContentRef.current) {
    return null;
  }

  return createPortal(children, bannerContentRef.current);
}
