import { Card, CardHeader, CardContent, Button } from "@/components/ui";
import { ErrorCount } from "@/hooks/queries";
import { IconAlertOctagonFilled } from "@tabler/icons-react";
import { t } from "i18next";
import { Trans } from "react-i18next";

export function FixInvalidShipmentsSection({
  filteredErrorCounts,
  downloadInvalidShipments,
}: {
  filteredErrorCounts: ErrorCount[];
  downloadInvalidShipments: (() => void) | null;
}) {
  return (
    <Card className="col-span-12 rounded-lg dark:bg-zinc-800">
      <CardHeader className="p-4 pb-8">
        <div className="flex items-center gap-x-3">
          <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-red-50 text-red-500">
            <IconAlertOctagonFilled className="h-5 w-5" />
          </div>
          <div className="flex flex-col">
            <h3 className="font-bold">{t("titles_fixInvalidShipments")}</h3>
            <p className="text-sm font-light">
              <Trans i18nKey="descriptions_fixInvalidShipments">
                These shipments cannot be included in the emissions
                calculations. Please{" "}
                {downloadInvalidShipments && (
                  <Button variant="link" className="p-0 text-sm">
                    download the invalid shipments
                  </Button>
                )}
                with highlighted errors, correct and re-upload an updated file
                to ensure accurate emissions calculations.
              </Trans>
              {/* {t("descriptions_fixInvalidShipments")} */}
            </p>
          </div>
        </div>
      </CardHeader>
      <CardContent className="px-4 pb-4">
        <div className="flex flex-col items-stretch gap-y-2">
          {filteredErrorCounts.map((ec, index) => (
            <div
              key={index}
              className="rounded-md border-l-[3px] border-red-500 bg-foreground/5 p-4 text-sm font-light"
            >
              {ec.column.length === 1 ? (
                <>
                  <span className="font-bold">{ec.count}</span>{" "}
                  {t("messages_missingColumn")}{" "}
                  <span className="font-bold italic">{ec.column[0]}</span>
                </>
              ) : (
                <>
                  <span className="font-bold">{ec.count}</span>{" "}
                  {t("messages_missingOneOfColumns")}{" "}
                  <span className="font-bold italic">
                    {ec.column.join(", ")}
                  </span>
                </>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
