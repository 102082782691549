import { Button } from "@/components/ui";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { t } from "i18next";
import { useRef } from "react";
import { Trans } from "react-i18next";
import { useDragAndDrop } from "../useDragAndDrop";
import { IconCloudUpload, IconFileDownload } from "@tabler/icons-react";

type ChooseFileViewProps = {
  className?: string;
  readyInfoBox: React.ReactNode;
  disabled?: boolean;
  maxSizeInMB: number;
  accept: string[];
  onChange: (files: FileList) => void;
  name: string;
};

export function ChooseFileView({
  className,
  readyInfoBox,
  disabled = false,
  maxSizeInMB = 100,
  accept,
  onChange,
  name,
}: ChooseFileViewProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isDragOver, onDragEnter, onDragLeave, onDragOver, onDrop } =
    useDragAndDrop(onChange);

  return (
    <motion.div layout className="space-y-4">
      {readyInfoBox}
      <div
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        className={cn("relative rounded-md", className)}
      >
        <div
          className={cn(
            `flex h-32 w-full flex-col items-center justify-center gap-3 rounded-md border p-6 text-sm`,
            disabled &&
              "cursor-not-allowed border-muted text-muted focus-within:outline-none hover:border-muted",
            isDragOver &&
              "border-4 border-dashed shadow-[inset_0_0_20px_0_#d1fae5]",
          )}
        >
          {isDragOver ? (
            <div className="pointer-events-none">
              <IconFileDownload
                stroke={1}
                className="block h-10 w-10 text-foreground/30"
              />
              <div className="text-xl text-foreground/30">Drop</div>
            </div>
          ) : (
            <>
              <Button
                variant="outline"
                className="whitespace-nowrap"
                onClick={() => fileInputRef.current?.click()}
              >
                <IconCloudUpload className="h-6" stroke={1} />
                <span className="sr-only">{t("common_uploadData")}</span>
              </Button>
              <div className="flex flex-col items-center">
                <Trans i18nKey="descriptions_fileDropText">
                  <div className="flex w-full items-baseline justify-center">
                    <Button
                      onClick={() => fileInputRef?.current?.click()}
                      variant="link"
                      className="h-auto whitespace-nowrap p-0 pr-1"
                    >
                      Click to Upload
                    </Button>{" "}
                    or drag and drop
                  </div>
                </Trans>
                <div>{`${accept.join(" / ")} - (max ${maxSizeInMB}mb)`}</div>
              </div>
              <label className="sr-only" htmlFor={name}>
                {t("common_uploadFile")}
              </label>
              <input
                name={name}
                id={name}
                ref={fileInputRef}
                className="hidden"
                type="file"
                accept={accept?.join(",") ?? "text/csv"}
                onChange={(e) => {
                  const files = e.target.files;
                  if (files) {
                    onChange(files);
                  }
                }}
                value={""}
                disabled={disabled}
              />
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
}
