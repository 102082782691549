import { UploadFileResponse } from "@/hooks/mutations";
import { TFunction } from "i18next";
import { InfoAlertProps } from "../..";
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconInfoCircle,
} from "@tabler/icons-react";

export const getInfoAlertProps = (
  t: TFunction<"translation", undefined>,
): Record<UploadFileResponse["status"], InfoAlertProps> => ({
  SUCCESS: {
    title: t("titles_initialCheckSuccess"),
    variant: "nxtLogGreen",
    IconComponent: IconCircleCheck,
    children: t("messages_initialCheckSuccess"),
  },
  PARTIAL_SUCCESS: {
    title: t("titles_initialCheckPartialSuccess"),
    variant: "nxtLogYellow",
    IconComponent: IconInfoCircle,
    children: t("messages_initialCheckPartialSuccess"),
  },
  FAILED: {
    title: t("titles_initialCheckFailed"),
    variant: "nxtLogRed",
    IconComponent: IconAlertTriangle,
    children: t("messages_initialCheckFailed"),
  },
});
