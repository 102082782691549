import { motion } from "framer-motion";
import { LiveChatNavButton, NavItems } from ".";
import { ExpandButton } from "./ExpandButton";
import { UserNav } from "../UserNav";
import { Separator } from "@/components/ui";
import { Link } from "react-router-dom";
import { useIsDemo } from "@/hooks";
import { NxtLogLogoSvg } from "@/components/shared/svg-components";
import { ProductTourButton } from "./ProductTour/ProductTourButton";

export function DesktopNavBar({
  className,
  isExpanded,
  handleExpand,
  isXl,
}: {
  className?: string;
  isExpanded: boolean;
  handleExpand: () => void;
  isXl?: boolean;
}) {
  const isDemo = useIsDemo();
  return (
    <motion.div layout className={className}>
      <div className="relative flex flex-col">
        {isExpanded && (
          <Link to={isDemo ? "/?demo=true" : "/"}>
            <NxtLogLogoSvg className="absolute left-4 top-3.5 h-6 text-white" />
          </Link>
        )}
        <ExpandButton
          hide={!isXl}
          isExpanded={isExpanded}
          handleExpand={handleExpand}
        />
        <nav className="pl-2 pt-[3.25rem]">
          <NavItems isExpanded={isExpanded} />
        </nav>
      </div>
      <div className="flex flex-col items-start pl-2">
        <ProductTourButton isExpanded={isExpanded} />
        <LiveChatNavButton isExpanded={isExpanded} />
        <Separator className="my-4 bg-zinc-700" />
        <div className="px-1.5">
          <UserNav />
        </div>
      </div>
    </motion.div>
  );
}
