import {
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogCancel,
} from "@/components/ui";
import { useMediaQuery } from "usehooks-ts";
import {
  DownloadGuidelinesButton,
  UploadShipmentForm,
} from "@/components/shared/UploadShipmentForm";
import { useIsDemo } from "@/hooks";
import { InfoAlert } from "@/components/shared";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataDelayConfirmation } from "../../InitialUpload/components";
import { useQueryClient } from "@tanstack/react-query";
import { upperFirst } from "@/lib/utils";
import { IconAlertTriangle, IconUpload } from "@tabler/icons-react";

export function UploadDialogDrawer({
  initialOpen,
  openState,
  setOpenState,
  trigger,
  title = "common_uploadData",
  description = "descriptions_importData",
  onClose,
}: {
  initialOpen?: boolean;
  openState?: boolean;
  setOpenState?: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  description?: string;
  trigger?: React.ReactNode;
  onClose?: () => void;
}) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(() => initialOpen ?? false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const isDemo = useIsDemo();
  const queryClient = useQueryClient();

  const open = openState ?? dialogOpen;
  const setOpen = setOpenState ?? setDialogOpen;

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open && isUploadSuccess) {
        queryClient
          .invalidateQueries({ queryKey: ["/shipments/files"] })
          .catch(console.error);
        setIsUploadSuccess(false);
      }
      setOpen(open);
      onClose?.();
    },
    [isUploadSuccess, onClose, queryClient, setOpen],
  );

  const dialogTrigger = trigger || (
    <Button>
      <IconUpload className="mr-2 h-5 w-5" stroke={1} />
      {upperFirst(t("common_uploadData"))}
    </Button>
  );

  const uploadCompleteButton = (
    <Button
      // variant="secondary"
      onClick={() => {
        onOpenChange(false);
        setUploadComplete(false);
      }}
    >
      {t("common_confirm")}
    </Button>
  );

  const content = isDemo ? (
    <div className="flex flex-col items-end gap-6">
      <InfoAlert
        variant="nxtLogYellow"
        title="Not available"
        IconComponent={IconAlertTriangle}
      >
        {t("descriptions_dataUploadUnavailable")}
      </InfoAlert>
      {isDesktop && <AlertDialogCancel>{t("common_cancel")}</AlertDialogCancel>}
    </div>
  ) : uploadComplete ? (
    <DataDelayConfirmation action={uploadCompleteButton} />
  ) : (
    <UploadShipmentForm
      onUploadComplete={() => setUploadComplete(true)}
      onCancel={() => onOpenChange(false)}
      onMutationSuccess={() => setIsUploadSuccess(true)}
    />
  );

  const desktopDialogHeader = uploadComplete ? null : (
    <AlertDialogHeader>
      <div className="inline-flex items-center justify-between">
        <AlertDialogTitle className="text-2xl">{t(title)}</AlertDialogTitle>
        <DownloadGuidelinesButton size="sm" />
      </div>
    </AlertDialogHeader>
  );

  const mobileDialogHeader = uploadComplete ? null : (
    <DrawerHeader>
      <DrawerTitle>{t(title)}</DrawerTitle>
      <DrawerDescription>{t(description)}</DrawerDescription>
    </DrawerHeader>
  );

  if (isDesktop) {
    return (
      <AlertDialog open={open} onOpenChange={onOpenChange}>
        <AlertDialogTrigger asChild>{dialogTrigger}</AlertDialogTrigger>
        <AlertDialogContent className="flex max-h-[calc(100vh-4rem)] flex-col sm:max-w-3xl">
          {desktopDialogHeader}
          {content}
        </AlertDialogContent>
      </AlertDialog>
    );
  }
  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerTrigger asChild>{dialogTrigger}</DrawerTrigger>
      <DrawerContent>
        {mobileDialogHeader}
        {content}
        <DrawerFooter>
          <DrawerClose>
            <Button variant="outline">{t("common_close")}</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
