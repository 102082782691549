import { useAuth } from "@/api/auth";
import { initialDemoProfile } from "@/api/auth/initialDemoProfile";
import { Loader } from "@/components/shared/loaders";
import { useIsDemo } from "@/hooks";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { CommandPalette } from "../shared";
import { ProductTourProvider } from "./NavigationLayout/productTourContext";

export function AuthCheck() {
  const isDemo = useIsDemo();
  const { keycloak, profile } = useAuth();

  const ready = keycloak.authenticated || isDemo;

  useEffect(() => {
    if (!keycloak.authenticated && !isDemo) {
      keycloak
        .login({
          redirectUri: window.location.href,
          loginHint:
            profile?.username === initialDemoProfile.username
              ? undefined
              : profile?.username,
        })
        ?.catch(console.error);
    }
  }, [isDemo, keycloak, keycloak.authenticated, profile?.username]);

  const loader = (
    <div className="flex h-screen w-full items-center justify-center bg-background">
      <Loader className="h-1/4" />
    </div>
  );

  if (!ready) {
    return loader;
  }

  return (
    <ProductTourProvider>
      <Outlet />
      <CommandPalette />
    </ProductTourProvider>
  );
}
