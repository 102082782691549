import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

export function ButtonTooltip({
  tooltipContent,
  children,
  side,
  isInverted = true,
  hasBorder = false,
}: {
  tooltipContent: ReactNode;
  children: ReactNode;
  side?: "top" | "right" | "bottom" | "left";
  isInverted?: boolean;
  hasBorder?: boolean;
}) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent
        side={side}
        className={cn(
          isInverted
            ? "bg-foreground text-background"
            : "bg-background text-foreground",
          hasBorder && "border",
        )}
      >
        {tooltipContent}
      </TooltipContent>
    </Tooltip>
  );
}
