import { useContext } from "react";
import { SocketProviderContext } from "./SocketProvider";

export function useSocket() {
  const Context = useContext(SocketProviderContext);
  if (Context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return Context;
}
