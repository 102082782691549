import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui";
import { PrefrencesForm } from "./PreferencesForm";

export function PreferencesDialog() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="whitespace-nowrap" size="sm" variant="outline">
          Customize Settings
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-5xl">
        <DialogHeader>
          <DialogTitle>Cookie Prefereces</DialogTitle>
          <DialogDescription>
            Customize your cookie preferences
          </DialogDescription>
          <PrefrencesForm isDialog />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
