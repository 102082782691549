export const cookiesData = {
  functional: [],
  analytics: [
    {
      cookie: "ph_*_posthog",
      purpose: "Ensures users are identified as the same user between sessions",
      type: "Third Party",
      expires: "365 days",
    },
  ],
};

export type CookieData = {
  cookie: string;
  purpose: string;
  type: string;
  expires: string;
};
