import { cn } from "@/lib/utils";

interface SubHeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
}

export function Subheading({ children, className, ...props }: SubHeadingProps) {
  if (!children) return null;
  return (
    <h4 className={cn("text-sm font-light", className)} {...props}>
      {children}
    </h4>
  );
}
