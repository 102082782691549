import { cn } from "@/lib/utils";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../ui";
import clsx from "clsx";
import { IconInfoCircle } from "@tabler/icons-react";

export function InfoHoverButton({
  children,
  className,
  size = "default",
}: {
  children?: React.ReactNode;
  className?: string;
  size?: "sm" | "default" | "lg";
}) {
  if (!children) return null;
  const sizeClassMap = {
    sm: "h-4 w-4",
    default: "h-5 w-5",
    lg: "h-6 w-6",
  };
  return (
    <HoverCard>
      <HoverCardTrigger className={cn("print:hidden", className)}>
        <IconInfoCircle
          stroke={1}
          className={clsx(
            "z-10 cursor-context-menu text-foreground/80",
            sizeClassMap[size],
          )}
        />
      </HoverCardTrigger>
      <HoverCardContent className="bg-foreground text-sm font-light text-background">
        {children}
      </HoverCardContent>
    </HoverCard>
  );
}
