import { useGetTermsAndConditions } from "@/hooks/queries";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { strapiBlockFormat } from "./strapiBlockFormat";

export function TermsAndConditionsContent() {
  const { data } = useGetTermsAndConditions();
  return (
    <div className="min-h-full">
      <BlocksRenderer
        content={data.attributes.termsAndConditions}
        blocks={strapiBlockFormat}
      />
    </div>
  );
}
