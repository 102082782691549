import { Button } from "@/components/ui";
import { UploadFileResponse } from "@/hooks/mutations";
import { handleAuthDownload } from "@/lib/utils";
import { IconDownload } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

interface ErrorReportButtonProps {
  status: UploadFileResponse["status"];
  fileId: number;
}

export function ErrorReportButton({ status, fileId }: ErrorReportButtonProps) {
  const { t } = useTranslation();

  if (status !== "PARTIAL_SUCCESS") return null;

  return (
    <Button
      variant="outline"
      onClick={() =>
        handleAuthDownload({
          url: `/reports/processing-report?fileId=${fileId}`,
          fileName: `${fileId}_shipments_invalid.xlsx`,
        })
      }
    >
      <IconDownload className="mr-2 h-4 w-4" stroke={1} />
      <span className="whitespace-nowrap">
        {t("common_downloadErrorReport")}
      </span>
    </Button>
  );
}
