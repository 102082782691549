import { LargeHeading } from "@/components/shared";
import { useGetShipmentsFile } from "@/hooks/queries";
import { IconArrowLeft } from "@tabler/icons-react";
import { useParams, useNavigate } from "react-router-dom";

export function Header() {
  const { fileId } = useParams();
  const {
    data: { name },
  } = useGetShipmentsFile({ fileId: Number(fileId) });
  const navigate = useNavigate();
  return (
    <>
      <div className="jr-overview-pg-upload-data">
        <LargeHeading className="flex items-center">
          <button onClick={() => navigate(-1)}>
            <IconArrowLeft className="mr-2" stroke={3} />
          </button>
          {name}
        </LargeHeading>
      </div>
    </>
  );
}
