import {
  CompletenessStatus,
  ShipmentsFileRecord,
  ProcessingStatus,
  Status,
} from "@/hooks/queries";
import { formatIsoDateString, handleAuthDownload } from "@/lib/utils";
import { createColumnHelper } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui";
import { ButtonTooltip, ConfirmDeleteDialog } from "@/components/shared";
import { TFunction } from "i18next";
import { ProcessingStatusDisplay } from "./ProcessingStatusDisplay";
import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { SolarRecordBroken } from "@/assets/icons";
import { Link } from "react-router-dom";
import SemiCircularProgressBar from "@/components/shared/SemiCircularProgressBar";
import {
  IconDots,
  IconDownload,
  IconExclamationCircle,
  IconInfoCircle,
  IconTimeline,
  IconTrash,
} from "@tabler/icons-react";

const columnHelper = createColumnHelper<ShipmentsFileRecord>();

export const getUploadDataTableColumns = (
  t: TFunction<"translation", undefined>,
  isDemo: boolean,
  deleteFile: UseMutateFunction<
    AxiosResponse,
    Error,
    {
      fileId: number;
    },
    unknown
  >,
  preloadShipmentsFileCache: (
    fileId: number,
    data: ShipmentsFileRecord,
  ) => void,
) => [
  /* Name */
  columnHelper.accessor("name", {
    id: "name",
    cell: (props) => props.getValue(),
    header: t("common_name"),
  }),

  /* Upload Date */
  columnHelper.accessor("createdAt", {
    id: "createdAt",
    cell: (props) => formatIsoDateString(props.getValue()),
    header: t("common_uploadDate"),
  }),

  /* Status */
  columnHelper.accessor("processingStatus", {
    id: "processingStatus",
    cell: (props) => {
      const value = props.getValue();
      return <ProcessingStatusDisplay processingStatus={value} />;
    },
    header: t("common_status"),
  }),

  /* Data Score */
  columnHelper.accessor(
    (row) => {
      const { dataScore } = row;
      if (!dataScore) {
        return null;
      }
      return row.processingStatus === ProcessingStatus.DataImportSuccess ? (
        <SemiCircularProgressBar progress={dataScore * 100} />
      ) : null;
    },
    {
      id: "dataScore",
      cell: (props) => props.getValue(),
      header: () => (
        <div className="flex items-center justify-start gap-3">
          <span>{t("common_dataScore")}</span>
          <ButtonTooltip tooltipContent={t("descriptions_dataScore")}>
            <IconInfoCircle className="size-5" stroke={1} />
          </ButtonTooltip>
        </div>
      ),
    },
  ),

  /* Valid Shipments */
  columnHelper.accessor(
    (row) =>
      row.completenessStatus === CompletenessStatus.Failed
        ? null
        : row.successfulShipments &&
            row.processingStatus === ProcessingStatus.DataImportSuccess
          ? `${row.successfulShipments}/${row.totalShipments}`
          : null,
    {
      id: "validShipments",
      cell: (props) => props.getValue(),
      header: t("common_validShipments"),
    },
  ),

  /* Data Validation */
  columnHelper.accessor(
    (row) => {
      const { hasReport, processingStatus, completenessStatus } = row;
      return processingStatus === ProcessingStatus.DataImportSuccess ||
        (completenessStatus === CompletenessStatus.Failed && hasReport) ? (
        <Link
          className="text-primary"
          to={row.id.toString()}
          // state={state}
          onClick={() => preloadShipmentsFileCache(row.id, row)}
        >
          <div className="flex items-center hover:underline">
            {hasReport ? (
              <>
                <IconExclamationCircle className="mr-1 h-4 w-4 shrink-0" />
                <span className="truncate">{t("common_viewErrorReport")}</span>
              </>
            ) : (
              <>
                <IconTimeline className="mr-1 h-4 w-4 shrink-0" />
                <span className="truncate">{t("common_viewInsights")}</span>
              </>
            )}
          </div>
        </Link>
      ) : null;
    },
    {
      id: "dataValidation",
      cell: (props) => props.getValue(),
      header: t("common_dataValidation"),
    },
  ),

  /* More (Three Dots) */
  columnHelper.accessor("id", {
    header: "",
    id: "more",
    cell: (props) => {
      const {
        row: {
          original: {
            completenessStatus,
            status,
            hasReport,
            name,
            processingReportFileName,
          },
        },
      } = props;
      const isPending = completenessStatus === CompletenessStatus.Pending;
      const canDelete = status !== null;
      const isDeleting = status === Status.DeletionInProgress;
      const originalFileName = name;
      if (isPending || !canDelete) {
        return <div className="h-7 w-7" />; // Placeholder to prevent layout shift
      }
      if (isDeleting) {
        return (
          <ButtonTooltip tooltipContent={t("common_deletionInProgress")}>
            <div className="inline-flex h-7 w-7 items-center justify-center rounded-full border">
              <span>
                <SolarRecordBroken className="h-5 w-5 animate-spin text-error-foreground" />
              </span>
            </div>
          </ButtonTooltip>
        );
      }
      const fileId = props.getValue();
      return (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <IconDots className="h-5 w-5" stroke={1} />
            {/* <DotsThree className="h-5 w-5" /> */}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {/* Need Translation */}
            {hasReport && (
              <DropdownMenuItem
                onSelect={() =>
                  handleAuthDownload({
                    url: `/reports/processing-report?fileId=${fileId}`,
                    fileName: processingReportFileName,
                    originalFileName,
                  })
                }
              >
                <IconDownload stroke={1} className="mr-2 h-4 w-4" />
                <span>Download error report</span>
              </DropdownMenuItem>
            )}
            <ConfirmDeleteDialog
              showTooltip={false}
              trigger={
                <button className="inline-flex w-full items-center px-2 py-1.5 text-sm text-destructive">
                  <IconTrash className="mr-2 h-4 w-4" stroke={1} />
                  {t("common_delete")}
                </button>
              }
              onConfirm={() => (isDemo ? null : deleteFile({ fileId }))}
            />
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  }),
];
