// Sentry initialization should be imported first!
import "./lib/sentry.ts";
import "./lib/posthog.ts";
import "./index.css";
import "./i18next/i18n.ts";
import { initKeycloak } from "./api/auth/keycloakInstance.tsx";
import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App.tsx";
import { Loader } from "./components/shared/loaders";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./components/shared/ErrorFallback.tsx";
import { IS_LOCAL } from "./lib/constants.ts";

const callback = (event: Event) => {
  if (IS_LOCAL) return;
  event.preventDefault();
  window.location.reload(); // reload the page
};

initKeycloak()
  .then(() => {
    window.addEventListener("vite:preloadError", callback);
  })
  .then(() => {
    ReactDOM.createRoot(document.getElementById("root")!).render(
      <React.StrictMode>
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <div className="flex h-screen w-full items-center justify-center">
              <ErrorFallback
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                error={error}
                resetErrorBoundary={resetErrorBoundary}
              />
            </div>
          )}
        >
          <React.Suspense
            fallback={
              <div className="flex h-screen w-screen items-center justify-center bg-background">
                <Loader className="h-1/4" />
              </div>
            }
          >
            <App />
          </React.Suspense>
        </ErrorBoundary>
      </React.StrictMode>,
    );
  })
  .catch(console.error);
