import { useSocket } from "@/context-providers/SocketProvider";
import { SocketEvent } from "@/context-providers/SocketProvider/types";
import { useEffect } from "react";

export function useSocketEvent<T>(
  eventName: SocketEvent,
  handler: (data: T) => void,
) {
  const { socket } = useSocket();
  useEffect(() => {
    if (!socket) return;
    socket.on(eventName, handler);
    return () => {
      socket.off(eventName, handler);
    };
  }, [eventName, handler, socket]);
}
