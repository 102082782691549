import { useMutation } from "@tanstack/react-query";
import { api, authHeader } from "@/api";
import { useIsDemo } from "..";
import { useToast } from "@/components/ui";
import { IconAlertTriangle } from "@tabler/icons-react";

interface CreateReportBody {
  from: string;
  to: string;
  name: string;
}

interface CreateReportResponse {
  message: string;
}

const createReport = async ({
  body,
  isDemo,
}: MutationVariables & { isDemo: boolean }) => {
  if (isDemo) {
    return Promise.reject({
      message: "Report creation not allowed in demo mode",
    });
  }
  const { data } = await api.post<CreateReportResponse>(
    "/reports/create",
    body,
    {
      headers: { ...authHeader() },
    },
  );
  return data;
};

interface MutationVariables {
  body: CreateReportBody;
}
export const useCreateReport = ({
  onSuccess,
}: { onSuccess?: () => void } = {}) => {
  const isDemo = useIsDemo();
  const { toast } = useToast();
  return useMutation({
    mutationFn: (mutationVariables: MutationVariables) =>
      createReport({ ...mutationVariables, isDemo }),
    onSuccess: () => {
      onSuccess?.();
    },
    onError: (error) => {
      toast({
        description: (
          <div className="flex items-center gap-3">
            <IconAlertTriangle className="h-5 w-5" />
            <span>{error.message}</span>
          </div>
        ),
        variant: "error",
      });
    },
  });
};
