import { InfoAlert } from "@/components/shared";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { IconClock } from "@tabler/icons-react";
export function DataDelayConfirmation({
  action,
  className,
}: {
  action: JSX.Element;
  className?: string;
}) {
  const { t } = useTranslation();
  return (
    <>
      <InfoAlert
        title={t("common_pleaseNote")}
        variant="default"
        className={cn("border-none", className)}
        IconComponent={IconClock}
      >
        <p className="mt-2">{t("descriptions_dataDelayConfirmation")}</p>
      </InfoAlert>
      <div className="flex w-full justify-end gap-2">{action}</div>
    </>
  );
}
