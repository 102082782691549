import { RequiredDateRange } from "@/components/layouts/NavigationLayout/dateRangeContext/DateRangeContextProvider2";
import { format } from "date-fns";
import { getDateLocale } from "./getDateLocale";

/**
 * Formats the date range from the RequiredDateRange object into a string.
 *
 * @param {RequiredDateRange} range - the date range object containing 'from' and 'to' dates
 * @return {string} the formatted date range string in the format "fromDate - toDate"
 */
export function formatDateRange(range: RequiredDateRange): string {
  const fromFormatted = format(range.from, "d MMM yy", {
    locale: getDateLocale(),
  });
  const toFormatted = format(range.to, "d MMM yy", { locale: getDateLocale() });
  return `${fromFormatted} - ${toFormatted}`;
}
