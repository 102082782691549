import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { ReportingStandards, ReportingTemplate, RouteEnum } from "@/types";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui";
import { upperFirst } from "lodash-es";
import { createReportFormSchema } from "./createReportFormSchema";
import { IconClock } from "@tabler/icons-react";
import { format } from "date-fns";
import { useCreateReport } from "@/hooks/mutations";
import { MediumHeading } from "@/components/shared";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { MonthRangePicker } from "@/components/shared/MonthRangePicker/MonthRangePicker";
import { useMonthRangePicker } from "@/components/shared/MonthRangePicker";

interface CreateReportFormProps {
  onDone: () => void;
}
export function CreateReportForm({ onDone }: CreateReportFormProps) {
  const { t } = useTranslation();
  const { mutate, isPending, data } = useCreateReport();
  const { dateRange, handlePresetClick, onApply } = useMonthRangePicker();

  const { from: startDate, to: endDate } = dateRange;

  const initialValues = {
    name: "",
    reportingStandards: ReportingStandards.ISO14083,
    keepInformed: false,
    template: ReportingTemplate.ISO14083,
  };
  const form = useForm<z.infer<typeof createReportFormSchema>>({
    resolver: zodResolver(createReportFormSchema),
    defaultValues: { ...initialValues },
  });

  function onSubmit(values: z.infer<typeof createReportFormSchema>) {
    const { name } = values;
    mutate({
      body: {
        from: format(startDate, "yyyy-MM-dd"),
        to: format(endDate, "yyyy-MM-dd"),
        name,
      },
    });
  }

  const formContent = (
    <>
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("common_reportName")}</FormLabel>
            <FormControl>
              <Input placeholder={t("common_enterReportName")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="reportingStandards"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("common_reportingStandards")}</FormLabel>
            <Select
              disabled
              onValueChange={field.onChange}
              defaultValue={field.value}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue
                    placeholder={t("common_selectReportingStandards")}
                  />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {Object.values(ReportingStandards).map((value) => (
                  <SelectItem key={value} value={value}>
                    {upperFirst(value)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormItem>
        <FormLabel>{t("common_selectReportingPeriod")}</FormLabel>
        <MonthRangePicker
          className="w-full justify-between"
          dateRange={dateRange}
          handleClick={handlePresetClick}
          onApply={onApply}
        />
      </FormItem>
      {/* Disable Keep Informed for now */}
      {/* <FormField
        control={form.control}
        name="keepInformed"
        render={({ field }) => (
          <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div className="space-y-1 leading-none">
              <FormLabel>{t("common_keepInformed")}</FormLabel>
            </div>
          </FormItem>
        )}
      /> */}
    </>
  );

  const formActions = (
    <>
      <div className="flex w-full justify-end gap-2 pt-6">
        <Button
          disabled={isPending}
          type="button"
          onClick={onDone}
          variant="outline"
          className="whitespace-nowrap"
        >
          {t("common_cancel")}
        </Button>
        <Button
          className="whitespace-nowrap"
          type="submit"
          isLoading={isPending}
        >
          {t("common_createReport")}
        </Button>
      </div>
    </>
  );

  const successView = data ? (
    <div className="flex flex-col gap-y-6">
      <div className="flex items-center gap-2">
        <IconClock className="h-7 w-7 text-amber-500" stroke={1} />
        <MediumHeading>{t("titles_reportBeingProcessed")}</MediumHeading>
      </div>
      <Trans>
        <p>
          It may take a while to process and create the report. We will notify
          you via email when it&apos;s ready. You can also see the status on the{" "}
          <Link
            to={RouteEnum.REPORTING}
            onClick={onDone}
            className="text-primary underline underline-offset-2"
          >
            Reporting page
          </Link>
          .
        </p>
      </Trans>
      <div className="flex w-full justify-end gap-2 pt-3">
        <Button type="button" onClick={onDone} className="whitespace-nowrap">
          Close
        </Button>
      </div>
    </div>
  ) : null;

  const formView = data ? null : (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        {formContent}
        {formActions}
      </form>
    </Form>
  );

  return (
    <>
      {formView}
      {successView}
    </>
  );
}
