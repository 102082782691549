import {
  Route,
  createBrowserRouter as noSentryRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { RouteEnum } from "../types/enums";
import {
  LegalPageContentLayout,
  NavigationLayout,
  NoNavLayout,
  RootLayout,
  UserStatusCheck,
} from "../components/layouts";
import { ProfileDetails } from "../components/pages/Profile/components/ProfileDetails";
import { PasswordUpdated } from "../components/pages/Profile/components/PasswordUpdated";
import TermsAndConditions from "../components/pages/TermsAndConditions/TermsAndConditions";
import { HomePageTitleAndActions } from "../components/pages/HomePage/components/MainContent/HomePageTitleAndActions";
import { CreateAccountForm } from "../components/pages/HomePage/components/CreateAccountForm";
import { sentryCreateBrowserRouter } from "../lib/sentry";
import { AuthCheck } from "../components/layouts/AuthCheck";
import { HomePage } from "../components/pages/HomePage";
import {
  AdminDashboard,
  CookiePolicy,
  Dashboard,
  DevSpace,
  Imprint,
  InitialUpload,
  PrivacyPolicy,
  Profile,
  Recommendation,
  Recommendations,
  Reporting,
  SupplyChain,
  UiDesignElements,
  UploadData,
} from "./lazyRoutes";
import { UploadDataIndex } from "@/components/pages/UploadData/components/UploadDataIndex";
import { UploadDataFileInsight } from "@/components/pages/UploadData/components/UploadDataFileInsights";

const createBrowserRouter =
  import.meta.env.VITE_SENTRY_ENVIRONMENT === "local"
    ? noSentryRouter
    : sentryCreateBrowserRouter;

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route element={<AuthCheck />}>
        <Route element={<NavigationLayout />}>
          <Route path={RouteEnum.DASHBOARD} element={<Dashboard />} />
          <Route path={RouteEnum.SUPPLY_CHAIN} element={<SupplyChain />} />
          <Route path={RouteEnum.REPORTING} element={<Reporting />} />
          <Route path={RouteEnum.RECOMMENDATIONS}>
            <Route index element={<Recommendations />} />
            <Route path=":slug" element={<Recommendation />} />
          </Route>
          <Route path={RouteEnum.UPLOADDATA} element={<UploadData />}>
            <Route index element={<UploadDataIndex />} />
            <Route path=":fileId" element={<UploadDataFileInsight />} />
          </Route>
          <Route
            path={RouteEnum.ADMIN_DASHBOARD}
            element={<AdminDashboard />}
          />
          <Route path={RouteEnum.DEV_SPACE} element={<DevSpace />} />
          <Route path={RouteEnum.UI_DESIGN} element={<UiDesignElements />} />
          <Route path={RouteEnum.PROFILE} element={<Profile />}>
            <Route index element={<ProfileDetails />} />
            <Route path="password-updated" element={<PasswordUpdated />} />
          </Route>
        </Route>
      </Route>
      <Route element={<NoNavLayout />}>
        <Route element={<HomePage />}>
          <Route index element={<HomePageTitleAndActions />} />
          <Route
            path={RouteEnum.CREATE_ACCOUNT}
            element={<CreateAccountForm />}
          />
        </Route>
        <Route element={<LegalPageContentLayout />}>
          <Route
            path={RouteEnum.TERMS_AND_CONDITIONS}
            element={<TermsAndConditions />}
          />
          <Route path={RouteEnum.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={RouteEnum.IMPRINT} element={<Imprint />} />
          <Route path={RouteEnum.COOKIE_POLICY} element={<CookiePolicy />} />
        </Route>
      </Route>
      <Route element={<AuthCheck />}>
        <Route element={<UserStatusCheck />}>
          <Route element={<NoNavLayout />}>
            <Route
              path={RouteEnum.INITIAL_UPLOAD}
              element={<InitialUpload />}
            />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);
