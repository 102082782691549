import { Link } from "react-router-dom";
import { RouteEnum } from "@/types";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui";
import { useTranslation } from "react-i18next";

export function DashboardLink() {
  const { t } = useTranslation();
  return (
    <Link
      to={RouteEnum.DASHBOARD}
      className={cn(buttonVariants({ variant: "link" }))}
    >
      {t("common_dashboard")}
    </Link>
  );
}
