import {} from "@/assets/icons";
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui";
import { cn, upperFirst } from "@/lib/utils";
import { useProductTour } from "../../../productTourContext/useProductTour";
import { useOnFirstLogin } from "@/hooks";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { IconInfoSquare } from "@tabler/icons-react";

export interface ProductTourButtonProps {
  isExpanded: boolean;
  label?: string;
}
export function ProductTourButton({
  isExpanded,
  label,
}: ProductTourButtonProps) {
  const { t } = useTranslation();
  const { startProductTour } = useProductTour();

  useOnFirstLogin(startProductTour);

  const button = (
    <Button
      variant="text"
      className="jr-start-pg-dashboard flex h-9 items-center gap-x-5 px-3 text-sm text-white/60 hover:text-white"
      onClick={startProductTour}
    >
      <motion.div layout>
        <IconInfoSquare className="h-6 w-6 shrink-0" stroke={1} />
      </motion.div>
      <span
        className={cn(
          {
            "animate-fade-in pr-4 font-light": isExpanded,
          },
          { "sr-only": !isExpanded },
        )}
      >
        {label ?? upperFirst(t("common_productTour"))}
      </span>
    </Button>
  );
  return (
    <>
      {isExpanded ? (
        button
      ) : (
        <Tooltip>
          <TooltipTrigger asChild>{button}</TooltipTrigger>
          <TooltipContent
            className="bg-foreground text-background"
            side="right"
          >
            {label ?? t("common_productTour")}
          </TooltipContent>
        </Tooltip>
      )}
    </>
  );
}
