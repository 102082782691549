import { format, parseISO, isValid } from "date-fns";
import { getDateLocale } from "./getDateLocale";

/**
 * Formats the given ISO date string according to the specified pattern.
 *
 * @param {string} isoDateString - The ISO date string to format.
 * @param {string} pattern - The pattern for formatting the date (default is "d MMM yyyy").
 * @return {string} The formatted date string or "Invalid date" if the input date is invalid.
 */
export function formatIsoDateString(
  isoDateString: string,
  pattern = "d MMM yyyy",
): string {
  const dateObj = parseISO(isoDateString);
  return isValid(dateObj)
    ? format(dateObj, pattern, { locale: getDateLocale() })
    : "Invalid date";
}
