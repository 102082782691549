import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { Toaster, TooltipProvider } from "./components/ui";
import { QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./api";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CookieConsentProvider } from "./components/CookieConsentBanner/CookieConsentProvider";
import { ThemeProvider } from "./context-providers";
import { Chatwoot } from "./components/Chatwoot";
import { SocketProvider } from "./context-providers/SocketProvider";
import { queryClient } from "./lib/queryClient";

function App() {
  return (
    <CookieConsentProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <SocketProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <Toaster />
            <TooltipProvider>
              <ThemeProvider>
                <RouterProvider router={router} />
                <Chatwoot />
              </ThemeProvider>
            </TooltipProvider>
          </SocketProvider>
        </AuthProvider>
      </QueryClientProvider>
    </CookieConsentProvider>
  );
}

export default App;
