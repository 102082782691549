import {
  IconFileTypePdf,
  IconFileTypeXls,
  IconInfoSquare,
  IconLanguage,
  IconLockPassword,
  IconLogout2,
  IconMessageCircle,
  IconSunMoon,
  IconUpload,
  IconUser,
} from "@tabler/icons-react";

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  // CommandShortcut,
} from "@/components/ui/command";
import { useCommandPaletteData } from "./useCommandPaletteData";
import { useCallback, useEffect, useRef, useState } from "react";
import { PageLink } from "./PageLink";
import { RouteEnum } from "@/types";
import { useIsDemo, useToggleChat } from "@/hooks";
import { useTranslation } from "react-i18next";
import { DATA_TEMPLATE_URL, GUIDELINES_URL } from "@/lib/constants";
import { CreateReportDialog } from "@/components/pages/Reporting/components/CreateReportDialog";
import { useProductTour } from "@/components/layouts/NavigationLayout/productTourContext/useProductTour";
import { useAuth } from "@/api/auth";
import { LanguageSelector } from "../LanguageSelector";
import { ThemeSwitch } from "@/components/layouts/NavigationLayout/components";

export function CommandPalette() {
  const { t } = useTranslation();
  const isDemo = useIsDemo();
  const [open, setOpen] = useState(false);
  const [createReportDialogOpen, setCreateReportDialogOpen] = useState(false);
  const { startProductTour } = useProductTour();
  const toggleChat = useToggleChat();
  const { keycloak } = useAuth();
  const VITE_APP_URL = import.meta.env.VITE_APP_URL;
  const logoutUrl = keycloak.createLogoutUrl({
    redirectUri: VITE_APP_URL,
  });
  const logoutRef = useRef<HTMLAnchorElement>(null);
  const updatePasswordRef = useRef<HTMLButtonElement>(null);
  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const { pages, internalPages } = useCommandPaletteData(closeDialog);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "j" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return (
    <>
      {/* <p className="text-sm text-muted-foreground">
        Press{" "}
        <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
          <span className="text-xs">⌘</span>J
        </kbd>
      </p> */}
      <CommandDialog open={open} onOpenChange={setOpen}>
        {/* TODO: translate */}
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          {/* TODO: translate */}
          <CommandGroup heading="Upload">
            <PageLink
              navItem={{
                to: `${RouteEnum.UPLOADDATA}${isDemo ? "?demo=true" : ""}`,
                state: { uploadDialogOpen: true },
                icon: <IconUpload className="h-4 w-4" stroke={1} />,
                tLabelKey: t("common_uploadData"),
                isVisible: true,
              }}
              closeDialog={closeDialog}
            />
          </CommandGroup>
          <CommandSeparator />
          {/* TODO: translate */}
          <CommandGroup heading="Export">
            <PageLink
              navItem={{
                to: `${RouteEnum.DASHBOARD}${isDemo ? "?demo=true" : ""}`,
                state: { printReport: true },
                icon: <IconFileTypePdf className="h-4 w-4" stroke={1} />,
                // TODO: translate
                tLabelKey: "Export dashboard as PDF",
                isVisible: true,
              }}
              closeDialog={closeDialog}
            />
            <CommandItem
              onSelect={() => {
                setCreateReportDialogOpen(true);
                closeDialog();
              }}
            >
              <span className="flex items-center gap-2">
                <IconFileTypeXls className="h-4 w-4" stroke={1} />
                <span>Export XLSX Report</span>
              </span>
            </CommandItem>
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading="Get help">
            <PageLink
              navItem={{
                to: GUIDELINES_URL,
                icon: <IconFileTypePdf className="h-4 w-4" stroke={1} />,
                // TODO: translate
                tLabelKey: "Download guidelines",
                isVisible: true,
                target: "_blank",
                rel: "noreferrer",
                download: true,
              }}
              closeDialog={closeDialog}
            />
            <PageLink
              navItem={{
                to: DATA_TEMPLATE_URL,
                icon: <IconFileTypeXls className="h-4 w-4" stroke={1} />,
                // TODO: translate
                tLabelKey: "Download sample data",
                isVisible: true,
                target: "_blank",
                rel: "noreferrer",
                download: true,
              }}
              closeDialog={closeDialog}
            />
            <CommandItem
              onSelect={() => {
                closeDialog();
                startProductTour();
              }}
            >
              <span className="flex items-center gap-2">
                <IconInfoSquare className="h-4 w-4" stroke={1} />
                <span>Start product tour</span>
              </span>
            </CommandItem>
            <CommandItem
              onSelect={() => {
                toggleChat();
                closeDialog();
              }}
            >
              <span className="flex items-center gap-2">
                <IconMessageCircle className="h-4 w-4" stroke={1} />
                <span>Chat with us</span>
              </span>
            </CommandItem>
          </CommandGroup>
          <CommandSeparator />
          {/* TODO: translate */}
          <CommandGroup heading="Account & settings">
            <PageLink
              navItem={{
                to: `${RouteEnum.PROFILE}${isDemo ? "?demo=true" : ""}`,
                icon: <IconUser className="h-4 w-4" stroke={1} />,
                // TODO: translate
                tLabelKey: "Profile",
                isVisible: true,
              }}
              closeDialog={closeDialog}
            />
            {!isDemo && (
              <CommandItem onSelect={() => updatePasswordRef.current?.click()}>
                <button
                  ref={updatePasswordRef}
                  disabled={isDemo}
                  className="flex items-center gap-2 p-0"
                  onClick={() =>
                    keycloak.login({
                      action: "UPDATE_PASSWORD",
                      redirectUri: `${VITE_APP_URL}${
                        RouteEnum.PROFILE
                      }/password-updated?sendEmail=true`,
                    })
                  }
                >
                  <IconLockPassword className="h-4 w-4" stroke={1} />
                  {t("common_updatePassword")}
                </button>
              </CommandItem>
            )}
            <CommandItem onSelect={() => logoutRef.current?.click()}>
              <a
                ref={logoutRef}
                href={logoutUrl}
                className="flex cursor-pointer items-center gap-2"
              >
                <IconLogout2 className="h-4 w-4" stroke={1} />
                {t("common_logout")}
              </a>
            </CommandItem>
            <CommandItem>
              <span className="flex items-center gap-2">
                <IconLanguage className="h-4 w-4" stroke={1} />
                <span className="flex items-center gap-2">
                  <span>{t("common_language") + ":"}</span>
                  <LanguageSelector className="h-5" size="sm" />
                </span>
              </span>
            </CommandItem>
            <CommandItem>
              <span className="flex items-center gap-2">
                <IconSunMoon className="h-4 w-4" stroke={1} />
                <span className="flex items-center gap-2">
                  <span>{t("common_theme") + ":"}</span>
                  <ThemeSwitch />
                </span>
              </span>
            </CommandItem>
          </CommandGroup>
          <CommandSeparator />
          {/* TODO: translate */}
          <CommandGroup heading="Page navigation">{pages}</CommandGroup>
          {internalPages.length > 0 && (
            <CommandGroup heading="Internal development pages">
              {internalPages}
            </CommandGroup>
          )}
        </CommandList>
      </CommandDialog>
      <CreateReportDialog
        trigger={<button className="hidden"></button>}
        openState={createReportDialogOpen}
        onOpenStateChange={setCreateReportDialogOpen}
      />
    </>
  );
}
