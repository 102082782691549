import { cn } from "@/lib/utils";
import { ReactNode } from "react";

export function LargeHeading({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return <h1 className={cn("text-2xl font-bold", className)}>{children}</h1>;
}
