import { cn, getDateLocale } from "@/lib/utils";
import {
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isEqual,
  isFuture,
  startOfMonth,
} from "date-fns";

interface MonthProps {
  month: Date;
  selectedRangeFrom: Date;
  selectedRangeTo?: Date;
  currentDate: Date;
  setCurrentRange: React.Dispatch<
    React.SetStateAction<{
      from: Date;
      to?: Date;
    }>
  >;
}
export function Month({
  month,
  selectedRangeFrom,
  selectedRangeTo,
  currentDate,
  setCurrentRange,
}: MonthProps) {
  function handleClick() {
    if (selectedRangeFrom && selectedRangeTo) {
      setCurrentRange({ from: month, to: undefined });
      return;
    }
    if (selectedRangeFrom) {
      if (isEqual(startOfMonth(currentDate), month)) {
        setCurrentRange((currentRange) => ({
          ...currentRange,
          to: currentDate,
        }));
      }
      setCurrentRange((currentRange) => ({
        ...currentRange,
        to: endOfMonth(month),
      }));
    }
  }

  return (
    <div
      className={cn(
        "relative px-1 text-center text-sm focus-within:relative focus-within:z-20",
        ((selectedRangeTo && isEqual(month, startOfMonth(selectedRangeTo))) ||
          isEqual(month, selectedRangeFrom)) &&
          "bg-foreground/10",
        selectedRangeTo &&
          isEqual(month, startOfMonth(selectedRangeTo)) &&
          "rounded-r-full",
        isEqual(month, selectedRangeFrom) && "rounded-l-full",
        isAfter(month, startOfMonth(selectedRangeFrom)) &&
          selectedRangeTo &&
          isBefore(month, startOfMonth(selectedRangeTo)) &&
          "bg-foreground/5",
      )}
    >
      <button
        name="day"
        className="inline-flex h-9 w-9 items-center justify-center rounded-md p-0 text-xs font-normal ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-40 aria-selected:opacity-100"
        disabled={
          isFuture(startOfMonth(month)) ||
          (!selectedRangeTo && isBefore(month, selectedRangeFrom))
        }
        tabIndex={-1}
        type="button"
        onClick={handleClick}
      >
        <time dateTime={format(month, "yyyy-MM-dd")}>
          {format(month, "MMM", { locale: getDateLocale() })}
        </time>
      </button>
    </div>
  );
}
