import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { InfoAlert } from "../../InfoAlert";
import { IconAlertTriangle } from "@tabler/icons-react";
export function UnsupportedFileAlert({
  isFileSupported,
  accept,
}: {
  isFileSupported: boolean;
  accept: string[];
}) {
  const { t } = useTranslation();

  if (isFileSupported) return null;

  return (
    <motion.div layout>
      <InfoAlert
        title={t("titles_unsupportedFileType")}
        variant="nxtLogRed"
        IconComponent={IconAlertTriangle}
      >
        <p>
          {`${t("descriptions_chooseFileExtension")}: ${accept.join(", ")}`}
        </p>
      </InfoAlert>
    </motion.div>
  );
}
