import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  buttonVariants,
} from "@/components/ui";
import { cn, upperFirst } from "@/lib/utils";
import { motion } from "framer-motion";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface NavButtonProps {
  isActive: boolean;
  isExpanded: boolean;
  icon: ReactNode;
  tLabelKey: string;
}
export function NavButton({
  isActive,
  isExpanded,
  icon,
  tLabelKey,
}: NavButtonProps) {
  const { t } = useTranslation();
  const button = (
    <motion.div
      layout
      className={cn(
        buttonVariants({ variant: "text" }),
        "flex h-12 w-12 shrink-0 rounded-sm p-0 !text-white/50",
        {
          "bg-gray-800": isActive && !isExpanded,
        },
      )}
    >
      <span className={cn({ "text-white": isActive })}>{icon}</span>
    </motion.div>
  );

  return (
    <div
      className={cn("flex items-center gap-x-2 rounded-sm text-sm", {
        "bg-gray-800": isActive,
      })}
    >
      {isExpanded ? (
        button
      ) : (
        <Tooltip>
          <TooltipTrigger asChild>{button}</TooltipTrigger>
          <TooltipContent
            className="bg-foreground text-background"
            side="right"
          >
            {upperFirst(t(tLabelKey))}
          </TooltipContent>
        </Tooltip>
      )}
      <div
        className={cn(
          {
            "animate-fade-in whitespace-nowrap pr-4 font-normal text-white/60":
              isExpanded,
          },
          {
            "text-white": isActive,
          },
          { "sr-only": !isExpanded },
        )}
      >
        {upperFirst(t(tLabelKey))}
      </div>
    </div>
  );
}
