import { api, authHeader } from "@/api";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useSendPasswordChangedEmail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [emailSent, setEmailSent] = useState(
    () => searchParams.get("sendEmail") !== "true",
  );

  useEffect(() => {
    if (emailSent) {
      return;
    }
    setEmailSent(true);
    setSearchParams({});
    api
      .post("/auth/password-change-success", null, {
        headers: { ...authHeader() },
      })
      .then(() => {})
      .catch(() => {});
  }, [emailSent, setSearchParams]);
};
