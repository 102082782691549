import { motion } from "framer-motion";

interface FileUploadProps {
  isFileSelected: boolean;
  fileView: React.ReactNode;
  chooseFileView: React.ReactNode;
}

export function FileUpload({
  isFileSelected,
  fileView,
  chooseFileView,
}: FileUploadProps) {
  return (
    <motion.div layout className="space-y-4">
      {isFileSelected ? fileView : chooseFileView}
    </motion.div>
  );
}
