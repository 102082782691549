import { ProcessingStatus } from "@/hooks/queries";

type Variant = "success" | "error" | "outline" | "warning" | "secondary";

export const processingStatusMap: Record<
  ProcessingStatus,
  {
    step: number;
    tKey: string;
    variant: Variant;
    iconClass?: string;
    statusDisplay: string;
  }
> = {
  PENDING: {
    step: 0,
    tKey: "common_pending",
    variant: "secondary",
    iconClass: "animate-spin",
    statusDisplay: "Busy",
  },
  INITIAL_CHECK_SUCCESS: {
    step: 1,
    tKey: "common_shipmentParsing",
    variant: "secondary",
    iconClass: "animate-spin",
    statusDisplay: "Busy",
  },
  INITIAL_CHECK_FAILED: {
    step: 0,
    tKey: "common_initialCheck",
    variant: "error",
    statusDisplay: "Failed",
  },
  SHIPMENT_PARSING_SUCCESS: {
    step: 2,
    tKey: "common_emissionCalculation",
    variant: "secondary",
    iconClass: "animate-spin",
    statusDisplay: "Busy",
  },
  SHIPMENTS_PARSING_FAILED: {
    step: 1,
    tKey: "common_shipmentParsing",
    variant: "error",
    statusDisplay: "Failed",
  },
  EMISSION_CALCULATION_SUCCESS: {
    step: 3,
    tKey: "common_dataImport",
    variant: "secondary",
    iconClass: "animate-spin",
    statusDisplay: "Busy",
  },
  EMISSION_CALCULATION_FAILED: {
    step: 2,
    tKey: "common_emissionCalculation",
    variant: "error",
    statusDisplay: "Failed",
  },
  DATA_IMPORT_SUCCESS: {
    step: 4,
    tKey: "common_complete",
    variant: "success",
    statusDisplay: "Success",
  },
  DATA_IMPORT_FAILED: {
    step: 3,
    tKey: "common_dataImport",
    variant: "error",
    statusDisplay: "Failed",
  },
};
