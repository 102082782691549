import { LargeHeading, MediumHeading } from "@/components/shared";
import clsx from "clsx";
import { StrapiBlocksOptions } from "../../Recommendation/components";

export const strapiBlockFormat: StrapiBlocksOptions = {
  heading: ({ children, level }) => {
    switch (level) {
      case 1:
        return <h1 className="mb-3 text-3xl font-bold">{children}</h1>;
      case 2:
        return <LargeHeading className="mb-3">{children}</LargeHeading>;
      default:
        return <MediumHeading className="mb-3">{children}</MediumHeading>;
    }
  },
  paragraph: ({ children }) => <p className="mb-3 font-light">{children}</p>,
  list: ({ children, format }) => (
    <ul
      className={clsx(
        "mb-3 list-inside pl-3 font-light",
        { "list-disc": format === "unordered" },
        { "list-decimal": format === "ordered" },
      )}
    >
      {children}
    </ul>
  ),
};
