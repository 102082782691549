import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { i18nextPlugin } from "translation-check";
import LocalBackend from "i18next-http-backend";
import LocizeBackend from "i18next-locize-backend";
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { IS_DEV, IS_LOCAL } from "@/lib/constants";
// import { locizePlugin } from "locize";

const version = IS_DEV ? "latest" : "production";

const supportedLngs = ["de", "en", "fr"] as const;

export type SupportedLngs = (typeof supportedLngs)[number];

const locizeOptions = {
  projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  referenceLng: "en",
  version,
};

IS_LOCAL
  ? i18n
      .use(LocalBackend)
      .use(initReactI18next)
      // .use(locizePlugin)
      .use(i18nextPlugin)
      .use(LanguageDetector)
      .init({
        supportedLngs,
        debug: true,
        fallbackLng: "en",
        interpolation: {
          escapeValue: false,
        },
        returnEmptyString: false,
      })
      .catch((error) => {
        // Handle any errors that occur during initialization
        console.error("Error initializing i18next:", error);
      })
  : i18n
      .use(ChainedBackend)
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        supportedLngs,
        // debug: true,
        fallbackLng: "en",
        interpolation: {
          escapeValue: false,
        },
        returnEmptyString: false,
        // saveMissing: IS_DEV, // do not use saveMissing in production
        backend: {
          backends: [
            LocizeBackend,
            resourcesToBackend(
              (lng = "en", ns = "translation") =>
                import(/* @vite-ignore */ `/locales/${lng}/${ns}.json`),
            ),
          ],
          backendOptions: [locizeOptions],
        },
        // react: {
        //   bindI18n: "languageChanged editorSaved",
        // },
      })
      .catch((error) => {
        // Handle any errors that occur during initialization
        console.error("Error initializing i18next:", error);
      });

export function getLocale() {
  return (i18n.resolvedLanguage || "en") as SupportedLngs;
}

export default i18n;
