import { useAuth } from "@/api/auth";
import clsx from "clsx";
import { useCallback } from "react";

export function useToggleChat() {
  const {
    keycloak: { subject },
    profile: { username, firstName, lastName } = {},
  } = useAuth();

  const toggleChat = useCallback(() => {
    if (subject && username && firstName && lastName) {
      window.$chatwoot.setUser(subject, {
        email: username,
        name: clsx(firstName, lastName),
      });
    }
    window.$chatwoot.toggle();
  }, [firstName, lastName, subject, username]);

  return toggleChat;
}
