import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  PaginationState,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui";
import { Fragment, useCallback, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import {
  ReportData,
  ShipmentsFileRecord,
  Status,
  useGetDataProcessingOverview,
} from "@/hooks/queries";
import { TablePagination } from "@/components/shared";
import { EmptyStateComponent } from "./EmptyStateComponent";
import { getUploadDataTableColumns } from "./getUploadDataTableColumns";
import { useTranslation } from "react-i18next";
import { TableLoader } from "@/components/shared/loaders";
import { useIsDemo } from "@/hooks";
import { useDeleteShipmentFile } from "@/hooks/mutations";
import { useQueryClient } from "@tanstack/react-query";

const AnimatedTableRow = motion(TableRow);
const AnimatedTableCell = motion(TableCell);
export const INITIAL_ENTRIES_PER_PAGE = 10;

export function DataProcessingTable() {
  const { t } = useTranslation();
  const isDemo = useIsDemo();
  const { mutate: deleteFile } = useDeleteShipmentFile();
  const queryClient = useQueryClient();

  const preloadShipmentsFileCache = useCallback(
    (fileId: number, data: ShipmentsFileRecord) => {
      queryClient.setQueryData(["/shipments/files", { fileId }], data);
    },
    [queryClient],
  );

  const tableColumns = useMemo(
    () =>
      getUploadDataTableColumns(
        t,
        isDemo,
        deleteFile,
        preloadShipmentsFileCache,
      ),
    [t, isDemo, deleteFile, preloadShipmentsFileCache],
  );

  const [pagination, setPagination] = useState<PaginationState>(() => ({
    pageIndex: 0,
    pageSize: INITIAL_ENTRIES_PER_PAGE,
  }));
  const { data, error, isLoading, isPlaceholderData } =
    useGetDataProcessingOverview({
      offset: pagination.pageIndex * INITIAL_ENTRIES_PER_PAGE,
      limit: pagination.pageSize,
    });

  const table = useReactTable({
    data: data?.data ?? [],
    columns: tableColumns,
    pageCount: data?.totalPages,
    getCoreRowModel: getCoreRowModel<ReportData>(),
    state: {
      pagination,
    },
  });

  const headerClassMap: Record<string, string> = {
    processingStatus: "w-[19rem]",
    name: "w-32 xl:w-auto",
    errorReport: "w-32 xl:w-auto",
    delete: "w-32 xl:w-auto",
    createdAt: "w-32 xl:w-auto",
  };

  if (isLoading) return <TableLoader />;

  if (error) {
    throw error;
  }

  if (!data || data.totalCount === 0) {
    return <EmptyStateComponent />;
  }

  return (
    <div className="py-3">
      <div className="animate-fade-in space-y-6 overflow-hidden rounded-xl border shadow-custom">
        <Table className="min-w-[900px] table-fixed text-xs">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={`hg-${headerGroup.id}`}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={`header-${header.id}`}
                      className={cn(
                        "truncate whitespace-nowrap first:pl-6 last:pr-6",
                        { "pl-6": header.column.id === "processingStatus" },
                        headerClassMap[header.column.id],
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="bg-white text-xs dark:bg-zinc-800">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                const isDeleting =
                  row.original.status === Status.DeletionInProgress;
                return (
                  <Fragment key={`rowFrag-${row.id}`}>
                    <AnimatedTableRow
                      className={cn({
                        "animate-pulse opacity-50": isPlaceholderData,
                      })}
                      layout
                      key={`row-${row.id}`}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <AnimatedTableCell
                          layout
                          key={`cell-${cell.id}`}
                          className={cn(
                            "truncate whitespace-nowrap py-4 first:pl-6 last:pr-6",
                            {
                              "animate-pulse-0":
                                isDeleting && cell.column.id !== "more",
                            },
                            { "text-right": cell.column.id === "more" },
                          )}
                        >
                          <motion.span layout>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </motion.span>
                        </AnimatedTableCell>
                      ))}
                    </AnimatedTableRow>
                  </Fragment>
                );
              })
            ) : (
              <AnimatedTableRow layout>
                <TableCell
                  key="noResults"
                  colSpan={tableColumns.length}
                  className="h-24 text-center"
                >
                  {t("common_noResults")}
                </TableCell>
              </AnimatedTableRow>
            )}
          </TableBody>
        </Table>
        {(data?.totalPages || 0) > 1 && (
          <div
            className={cn("flex justify-center border-t py-4", {
              "pointer-events-none opacity-50": isPlaceholderData,
            })}
          >
            <TablePagination
              page={pagination.pageIndex + 1}
              setPage={(page: number) => {
                setPagination((curr) => ({ ...curr, pageIndex: page - 1 }));
              }}
              totalPages={data?.totalPages ?? 0}
            />
          </div>
        )}
      </div>
    </div>
  );
}
