import { strapi } from "@/api";
import { useSuspenseQuery } from "@tanstack/react-query";
import { BlocksContent } from "@strapi/blocks-react-renderer";

export interface TermsAndConditionsResponse {
  data: TermsAndConditionsData;
}

interface TermsAndConditionsData {
  id: number;
  attributes: TermsAndConditionsAttributes;
}

interface TermsAndConditionsAttributes {
  termsAndConditions: BlocksContent;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
}

const getTermsAndConditions = async (): Promise<TermsAndConditionsData> => {
  const response = await strapi.get<TermsAndConditionsResponse>(
    `/api/terms-and-conditions-page`,
  );
  const data = response.data;
  return data.data;
};

export function useGetTermsAndConditions() {
  return useSuspenseQuery({
    queryKey: ["termsAndConditions"],
    queryFn: getTermsAndConditions,
  });
}
