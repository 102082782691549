import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui";
import { CookieData } from "./cookiesData";

export function CookieTable({ cookies }: { cookies: CookieData[] }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">Cookie</TableHead>
          <TableHead>Purpose</TableHead>
          <TableHead>Type</TableHead>
          <TableHead className="text-right">Expires</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {cookies.map((cookie) => (
          <TableRow key={cookie.cookie}>
            <TableCell className="font-medium">{cookie.cookie}</TableCell>
            <TableCell>{cookie.purpose}</TableCell>
            <TableCell>{cookie.type}</TableCell>
            <TableCell className="text-right">{cookie.expires}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
