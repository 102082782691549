import { useAuth } from "@/api/auth";
import { buttonVariants } from "@/components/ui";
import { cn } from "@/lib/utils";
import { RouteEnum } from "@/types";
import { useTranslation } from "react-i18next";

const VITE_APP_URL = import.meta.env.VITE_APP_URL;

export function LoginLink({
  className,
  children,
}: {
  className?: string;
  children?: string;
}) {
  const { keycloak } = useAuth();
  const { t } = useTranslation();
  return (
    <a
      href={keycloak?.createLoginUrl({
        redirectUri: `${VITE_APP_URL}${RouteEnum.INITIAL_UPLOAD}`,
      })}
      className={cn(buttonVariants({ variant: "link" }), className)}
    >
      {children || t("common_login")}
    </a>
  );
}
