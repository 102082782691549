import { api, authHeader } from "@/api";

/**
 * Downloads a file from the specified URL using authentication headers.
 *
 * @param {Object} options - The options for downloading the file.
 * @param {string} options.url - The URL of the file to download.
 * @param {string} [options.fileName] - The name of the downloaded file.
 * @param {string} [options.originalFileName] - The original name of the uploaded file.
 * @param {string} [options.documentType="error_report"] - The type of document being downloaded.
 * @return {Promise<void>} - A promise that resolves when the file is downloaded successfully, or rejects with an error if the download fails.
 */
export const handleAuthDownload = async ({
  url,
  fileName,
  originalFileName,
  documentType = "error_report",
}: {
  url: string;
  fileName?: string;
  originalFileName?: string;
  documentType?: string;
}) => {
  try {
    const response = await api.get(url, {
      responseType: "arraybuffer",
      headers: { ...authHeader() },
    });
    // Create a URL for the blob
    const urlFromBlob = window.URL.createObjectURL(new Blob([response.data]));

    // Creating an anchor element to initiate download
    const a = document.createElement("a");
    a.href = urlFromBlob;
    a.download = fileName ? fileName : `${documentType}_${originalFileName}`;
    document.body.appendChild(a);
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(urlFromBlob);
    document.body.removeChild(a);
  } catch (error) {
    console.error("Download error:", error);
  }
};
