import { Link } from "react-router-dom";
import { RouteEnum } from "@/types";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const linksData = [
  {
    title: "Terms and Conditions",
    tTitle: "common_termsAndConditions",
    link: RouteEnum.TERMS_AND_CONDITIONS,
  },
  {
    title: "Privacy Policy",
    tTitle: "common_privacyPolicy",
    link: RouteEnum.PRIVACY_POLICY,
  },
  {
    title: "Imprint",
    tTitle: "common_imprint",
    link: RouteEnum.IMPRINT,
  },
  {
    title: "Cookie Policy",
    tTitle: "common_cookiePolicy",
    link: RouteEnum.COOKIE_POLICY,
  },
];

const year = new Date().getFullYear();

function FooterLegalLinksCore() {
  const { t } = useTranslation();
  return (
    <>
      <div className="z-10 flex flex-col gap-3">
        <p className="text-sm">© NxtLog {year}</p>
        <div className="flex flex-wrap gap-x-4 gap-y-0.5 text-sm">
          {linksData.map(({ tTitle, link }) => (
            <div key={tTitle}>
              <Link
                to={link}
                className="text-gray-500 hover:text-emerald-200 hover:underline"
              >
                {t(tTitle)}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export const FooterLegalLinks = memo(FooterLegalLinksCore);
