import { createContext, useMemo, useState } from "react";
import { startOfYear, subYears } from "date-fns";

const today = new Date();
const startOfCurrentYear = startOfYear(today);
const threeYearsAgoStart = subYears(startOfCurrentYear, 3);

// eslint-disable-next-line react-refresh/only-export-components
export const defaultDateRange = {
  from: threeYearsAgoStart,
  to: today,
};

export interface RequiredDateRange {
  from: Date;
  to: Date;
}

export const DateRangeContext2 = createContext(
  {} as {
    dateRange: RequiredDateRange;
    setDateRange: React.Dispatch<React.SetStateAction<RequiredDateRange>>;
  },
);

export function DateRangeContextProvider2({
  children,
}: {
  children: React.ReactNode;
}) {
  const [dateRange, setDateRange] = useState<RequiredDateRange>(
    () => defaultDateRange,
  );

  const contextValue = useMemo(
    () => ({
      dateRange,
      setDateRange,
    }),
    [dateRange],
  );

  return (
    <DateRangeContext2.Provider value={contextValue}>
      {children}
    </DateRangeContext2.Provider>
  );
}
