import {
  Pagination,
  PaginationButton,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationNextButton,
  PaginationPreviousButton,
} from "@/components/ui";
import { usePagination } from "@/hooks";
import { cn } from "@/lib/utils";

interface TablePaginationProps {
  totalPages: number;
  page: number;
  setPage: (page: number) => void;
}

export function TablePagination({
  totalPages,
  page,
  setPage,
}: TablePaginationProps) {
  const pagination = usePagination({
    total: totalPages,
    page,
    onChange: setPage,
  });
  return (
    <Pagination>
      <PaginationContent className="text-emerald-800 dark:text-emerald-600">
        <PaginationItem>
          <PaginationPreviousButton size="sm" onClick={pagination.previous} />
        </PaginationItem>
        {pagination.range.map((item, i) =>
          item === "dots" ? (
            <PaginationItem
              className="h-6 w-6 text-muted-foreground"
              key={item + i}
            >
              <PaginationEllipsis className="h-6 w-6" />
            </PaginationItem>
          ) : (
            <PaginationItem key={item}>
              <PaginationButton
                className={cn("h-7 w-7 rounded-full", {
                  "bg-emerald-800 text-white dark:bg-emerald-600":
                    item === page,
                })}
                size="sm"
                onClick={() => pagination.setPage(item)}
                isActive={item === page}
              >
                {item}
              </PaginationButton>
            </PaginationItem>
          ),
        )}
        <PaginationItem>
          <PaginationNextButton size="sm" onClick={pagination.next} />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
}
