import { SwitchRoot, SwitchThumb } from "@/components/ui";
import { useThemeContext } from "@/context-providers";
import { cn } from "@/lib/utils";
import { IconMoon, IconSun } from "@tabler/icons-react";

export function ThemeSwitch({ className }: { className?: string }) {
  const { theme, setTheme } = useThemeContext();
  const isDark = theme === "dark";
  return (
    <SwitchRoot
      className={cn(
        "h-6 w-10 data-[state=checked]:bg-black dark:border dark:border-white/50",
        className,
      )}
      checked={isDark}
      onCheckedChange={() => setTheme(isDark ? "light" : "dark")}
    >
      <SwitchThumb className="flex h-5 w-5 items-center justify-center data-[state=checked]:translate-x-4 [[data-state=checked]&>.light]:hidden [[data-state=unchecked]&>.dark]:hidden">
        <IconSun
          className="light h-3.5 w-3.5 text-muted-foreground"
          stroke={1}
        />
        <IconMoon
          className="dark h-3.5 w-3.5 text-muted-foreground"
          stroke={1}
        />
      </SwitchThumb>
    </SwitchRoot>
  );
}
