import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useIsDemo } from "..";
import mockDataProcessingData from "@/components/pages/UploadData/mockDataProcessingData";
import { api, authHeader } from "@/api";
import {
  DataProcessingOverviewResponse,
  LimitOffsetQueryParams,
} from "./types";

const getDataProcessingOverview = async (params: {
  limit: string;
  offset: string;
}) => {
  const response = await api.get<DataProcessingOverviewResponse>(
    `/shipments/files`,
    {
      params,
      headers: { ...authHeader() },
    },
  );
  return response.data;
};

export function useGetDataProcessingOverview({
  limit = 10,
  offset = 0,
}: LimitOffsetQueryParams) {
  const isDemo = useIsDemo();
  const params = {
    limit: limit.toString(),
    offset: offset.toString(),
  };

  return useQuery<DataProcessingOverviewResponse>({
    queryKey: ["/shipments/files", { ...params }],
    queryFn: () =>
      isDemo
        ? Promise.resolve(
            mockDataProcessingData as DataProcessingOverviewResponse,
          )
        : getDataProcessingOverview(params),
    placeholderData: keepPreviousData,
  });
}
