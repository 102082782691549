import { useMutation } from "@tanstack/react-query";
import { api, authHeader } from "@/api";
import { useToast } from "@/components/ui";
import { useTranslation } from "react-i18next";
import { IconAlertTriangle } from "@tabler/icons-react";

const startFileProcessing = async (mutationVariables: MutationVariables) => {
  await api.post("/data-import/process/shipment", mutationVariables, {
    headers: { ...authHeader() },
  });
};

interface MutationVariables {
  fileId: number;
}
export const useStartFileProcessing = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const { toast } = useToast();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: (mutationVariables: MutationVariables) =>
      startFileProcessing(mutationVariables),
    onSuccess: onSuccess,
    onError: (_error) => {
      toast({
        description: (
          <div className="flex items-center gap-3">
            <IconAlertTriangle className="h-5 w-5 shrink-0" />
            <span>{t("messages_failedFileProcessingStart")}</span>
          </div>
        ),
        variant: "error",
      });
    },
  });
};
