import {
  MainContent,
  ContentWithoutSidebar,
  HeaderContent,
} from "../../../../layouts";
import { Main } from "./Main";
import { Header } from "./Header";

export function UploadDataFileInsight() {
  return (
    <>
      <HeaderContent>
        <Header />
      </HeaderContent>
      <ContentWithoutSidebar>
        <MainContent>
          <Main />
        </MainContent>
      </ContentWithoutSidebar>
    </>
  );
}
