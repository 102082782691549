import { ErrorBoundary } from "react-error-boundary";
import { Button } from "../ui";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { InfoAlert } from ".";
import { IconAlertTriangle } from "@tabler/icons-react";

export function QueryErrorBoundary({
  showResetButton = true,
  children,
}: {
  showResetButton?: boolean;
  children: React.ReactNode;
}) {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div className="min-h-400 flex w-full flex-col items-center justify-between gap-6">
          <InfoAlert
            className="w-auto"
            variant="nxtLogRed"
            title="Something went wrong"
            IconComponent={IconAlertTriangle}
          >
            <pre className="whitespace-normal py-4 text-2xs font-light text-gray-500">
              {JSON.stringify(error, null, 2)}
            </pre>
          </InfoAlert>
          {showResetButton && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => resetErrorBoundary()}
            >
              Retry ?
            </Button>
          )}
        </div>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}
