import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { InfoAlert } from "../../InfoAlert";
import { IconAlertTriangle } from "@tabler/icons-react";
export function FileTooBigAlert({
  maxSizeInMB,
  isFileTooBig,
}: {
  maxSizeInMB: number;
  isFileTooBig: boolean;
}) {
  const { t } = useTranslation();

  if (!isFileTooBig) return null;

  return (
    <motion.div layout>
      <InfoAlert
        title={t("titles_fileTooBig")}
        variant="nxtLogRed"
        IconComponent={IconAlertTriangle}
      >
        <p>{`${t("descriptions_chooseSmallerFile")} ${maxSizeInMB} MB`}</p>
      </InfoAlert>
    </motion.div>
  );
}
