import { ButtonTooltip } from "@/components/shared";
import { Badge } from "@/components/ui";
import { ProcessingStatus } from "@/hooks/queries";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { processingStatusMap } from "./processingStatusMap";
import { Fragment } from "react/jsx-runtime";
import {
  IconCircleCheck,
  IconCircleX,
  IconClock,
  IconLoader2,
} from "@tabler/icons-react";

const TOTAL_STEPS = 4;

const hoverTextTKeys = [
  "common_initialCheck",
  "common_shipmentParsing",
  "common_emissionCalculation",
  "common_dataImport",
];

export function ProcessingStatusDisplay({
  processingStatus,
}: {
  processingStatus: ProcessingStatus;
}) {
  const { t } = useTranslation();
  const statusData = processingStatusMap[processingStatus];
  const badgeProps = {
    variant: statusData.variant,
    className: "rounded-full shadow-none whitespace-nowrap ml-[1px] pl-1",
  };

  const { step: currentStep, statusDisplay, iconClass } = statusData;
  const Icon = iconClass === "animate-spin" ? IconLoader2 : IconCircleX;

  const isFailedStatus = statusDisplay === "Failed";

  if (processingStatus === ProcessingStatus.DataImportSuccess) {
    return (
      <Badge {...badgeProps}>
        <IconCircleCheck className="mr-2 h-4 w-4 shrink-0" stroke={1} />
        <span>{t("common_successful")}</span>
      </Badge>
    );
  }

  return (
    <div className="flex items-center">
      {hoverTextTKeys.slice(0, currentStep).map((tKey, i) => (
        <Fragment key={`check-${i}`}>
          {i !== 0 && (
            <div className="h-[1px] w-1 shrink-0 bg-success-foreground" />
          )}
          <ButtonTooltip key={tKey} tooltipContent={t(tKey)} side="top">
            <IconCircleCheck
              className="h-4 w-4 shrink-0 cursor-context-menu text-success-foreground"
              stroke={1}
            />
          </ButtonTooltip>
        </Fragment>
      ))}
      {currentStep < TOTAL_STEPS && (
        <>
          {currentStep !== 0 && (
            <div
              className={cn("h-[1px] w-1 shrink-0 bg-foreground/50", {
                "bg-error-foreground": isFailedStatus,
              })}
            />
          )}
          <Badge {...badgeProps}>
            {Icon && (
              <Icon className={cn("mr-2 h-4 w-4", iconClass)} stroke={1} />
            )}
            <span className={!isFailedStatus ? "animate-pulse" : ""}>
              {t(statusData.tKey)}
              {isFailedStatus && " - " + t("common_failed")}
            </span>
          </Badge>
        </>
      )}
      {hoverTextTKeys.slice(currentStep + 1).map((tKey, i2) =>
        isFailedStatus ? null : (
          <Fragment key={`$dash-${currentStep + i2 + 1}`}>
            <div className="h-[1px] w-1 shrink-0 bg-foreground/50" />
            <ButtonTooltip key={tKey} tooltipContent={t(tKey)} side="top">
              <IconClock
                className="h-4 w-4 shrink-0 cursor-context-menu text-foreground/50"
                stroke={1}
              />
            </ButtonTooltip>
          </Fragment>
        ),
      )}
    </div>
  );
}
