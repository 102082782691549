import React from "react";

export const Dashboard = React.lazy(
  () => import("../components/pages/Dashboard"),
);
export const SupplyChain = React.lazy(
  () => import("../components/pages/SupplyChain"),
);
export const Reporting = React.lazy(
  () => import("../components/pages/Reporting"),
);
export const Recommendations = React.lazy(
  () => import("../components/pages/Recommendations"),
);
export const Recommendation = React.lazy(
  () => import("../components/pages/Recommendation"),
);
export const UploadData = React.lazy(
  () => import("../components/pages/UploadData"),
);
export const AdminDashboard = React.lazy(
  () => import("../components/pages/AdminDashboard"),
);
export const DevSpace = React.lazy(
  () => import("../components/pages/DevSpace"),
);
export const UiDesignElements = React.lazy(
  () => import("../components/pages/UiDesignElements"),
);
export const Profile = React.lazy(() => import("../components/pages/Profile"));
export const InitialUpload = React.lazy(
  () => import("../components/pages/InitialUpload"),
);
export const PrivacyPolicy = React.lazy(
  () => import("../components/pages/PrivacyPolicy"),
);
export const Imprint = React.lazy(() => import("../components/pages/Imprint"));
export const CookiePolicy = React.lazy(
  () => import("../components/pages/CookiePolicy"),
);
