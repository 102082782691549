import { navItemsData } from "@/components/layouts/NavigationLayout/components/LeftNavBar/NavItems/navItemsData";
import { useIsDemo } from "@/hooks";
import { useGetMe } from "@/hooks/queries";
import { PageLink } from ".";
import { IconComponents, IconFlask } from "@tabler/icons-react";
import { IS_DEV } from "@/lib/constants";
import { RouteEnum } from "@/types";

const searchParams = new URLSearchParams(window.location.search);
const isDemo = searchParams.get("demo") === "true";

export const internalPages = [
  {
    to: `${RouteEnum.UI_DESIGN}${isDemo ? "?demo=true" : ""}`,
    icon: <IconComponents className="h-6 w-6" stroke={1} />,
    tLabelKey: "UI & Design",
    isVisible: IS_DEV,
  },
  {
    to: `${RouteEnum.DEV_SPACE}${isDemo ? "?demo=true" : ""}`,
    icon: <IconFlask className="h-6 w-6" stroke={1} />,
    tLabelKey: "Dev Playground",
    isVisible: IS_DEV,
  },
];

export const useCommandPaletteData = (closeDialog: () => void) => {
  const isDemo = useIsDemo();
  const { data: { isAdmin = false } = {} } = useGetMe({ enabled: !!isDemo });
  return {
    pages: navItemsData(isDemo, isAdmin)
      // .concat(additionalPages)
      .filter((navItem) => navItem.isVisible)
      .map((navItem) => (
        <PageLink
          key={navItem.to}
          navItem={navItem}
          closeDialog={closeDialog}
        />
      )),
    internalPages: internalPages
      .filter((page) => page.isVisible)
      .map((page) => (
        <PageLink key={page.to} navItem={page} closeDialog={closeDialog} />
      )),
  };
};
