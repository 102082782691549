import { UiwFileExcel } from "@/assets/icons";
import { Button } from "@/components/ui";
import { calculateFileSize } from "@/lib/utils";
import {
  IconCircleCheck,
  IconCloudUpload,
  IconTrash,
} from "@tabler/icons-react";

export function UploadProgressBar({
  file,
  uploadProgress,
  onDelete,
}: {
  file: File;
  uploadProgress: number;
  onDelete: (document: File) => void;
}) {
  return (
    <div className="sticky top-0 z-20 mt-3 rounded-md border bg-accent dark:bg-background">
      <div className="relative flex w-full items-center justify-between gap-3 p-4">
        <div
          className="absolute inset-0 h-full w-0 bg-emerald-500/10"
          style={{
            width: `${uploadProgress > 100 ? 100 : uploadProgress}%`,
          }}
        />
        <div className="flex items-center gap-3">
          <UiwFileExcel className="col-span-1 mr-4 h-9 w-9 text-emerald-600" />
          <div className="col-span-4 flex flex-col">
            <span className="overflow-hidden text-ellipsis text-sm text-muted-foreground">
              {file.name}
            </span>
            {!!file.size && (
              <span className="text-xs tracking-wider text-muted-foreground">
                {calculateFileSize(file.size)}
              </span>
            )}
          </div>
        </div>

        {uploadProgress === 0 ? (
          // <button
          //   className="rounded-md text-muted-foreground hover:text-muted focus:outline-2 focus:outline-offset-2 focus:outline-emerald-300 "
          //   onClick={() => onDelete(file)}
          //   type="button"
          // >
          //   <Cross1Icon className="h-6 w-6 stroke-2 text-gray-600" />
          // </button>
          <Button
            variant="outline"
            size="icon"
            className="rounded-full hover:bg-red-200 hover:text-red-900 dark:hover:bg-red-900 dark:hover:text-red-200"
            onClick={() => onDelete(file)}
          >
            <IconTrash className="h-7 w-7" stroke={1} />
          </Button>
        ) : uploadProgress < 100 ? (
          <div className="text-muted-foreground">
            <IconCloudUpload stroke={1} className="block h-7" />
            <p className="w-full text-center text-sm">{uploadProgress}%</p>
          </div>
        ) : (
          <IconCircleCheck
            className="ml-auto mr-0 h-7 w-7 text-primary"
            stroke={1}
          />
        )}
      </div>
    </div>
  );
}
