import { useCallback, useMemo, useState } from "react";

type EventMethods = {
  preventDefault: () => void;
  stopPropagation: () => void;
};

const preventDefaultStopPropagation = (e: EventMethods) => {
  e.preventDefault();
  e.stopPropagation();
};

export function useDragAndDrop(onChange: (files: FileList) => void) {
  // const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const onDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    preventDefaultStopPropagation(e);
    e.dataTransfer.dropEffect = "copy";
    setIsDragOver(true);
  }, []);

  const onDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    preventDefaultStopPropagation(e);
    e.dataTransfer.dropEffect = "none";
    setIsDragOver(false);
  }, []);

  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    preventDefaultStopPropagation(e);
    e.dataTransfer.dropEffect = "copy";
  }, []);

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      preventDefaultStopPropagation(e);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        onChange(e.dataTransfer.files);
        e.dataTransfer.clearData();
      }
      setIsDragOver(false);
    },
    [onChange],
  );

  return useMemo(
    () => ({
      // fileInputRef,
      isDragOver,
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
    }),
    [
      // fileInputRef,
      isDragOver,
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
    ],
  );
}
