import { IS_DEV } from "@/lib/constants";

export const languages: Record<
  string,
  {
    name: string;
    flag: string;
    code: string;
    isVisible: boolean;
  }
> = {
  en: {
    name: "English",
    flag: "🇬🇧",
    code: "en",
    isVisible: true,
  },
  de: {
    name: "Deutsch",
    flag: "🇩🇪",
    code: "de",
    isVisible: true,
  },
  fr: {
    name: "Français",
    flag: "🇫🇷",
    code: "fr",
    isVisible: true,
  },
  cimode: {
    name: "Cimode",
    flag: "🇨",
    code: "cimode",
    isVisible: IS_DEV,
  },
};
