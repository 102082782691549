import { buttonVariants } from "@/components/ui";
import { RouteEnum } from "@/types";
import { Link } from "react-router-dom";
import { useSendPasswordChangedEmail } from "./useSendPasswordChangedEmail";

export function PasswordUpdated() {
  useSendPasswordChangedEmail();
  return (
    <div className="flex flex-col gap-y-6 p-6">
      <h1 className="text-3xl font-semibold">Password Updated Successfully</h1>
      <Link
        to={RouteEnum.PROFILE}
        className={buttonVariants({ variant: "default" })}
      >
        {`< Back to Profile`}
      </Link>
    </div>
  );
}
