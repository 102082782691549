import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ConfirmDeleteAlertDialogProps {
  trigger?: React.ReactNode;
  showTooltip?: boolean;
  onConfirm: () => void;
  title?: string;
  description?: string;
}

export function ConfirmDeleteDialog({
  trigger,
  onConfirm,
  title,
  description,
}: ConfirmDeleteAlertDialogProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <TooltipTrigger asChild>
          <AlertDialogTrigger asChild>
            {trigger ? (
              trigger
            ) : (
              <Button
                variant="outline"
                size="icon"
                className="h-7 w-7 whitespace-nowrap rounded-full hover:text-red-500"
              >
                <IconTrash className="h-4 w-4 rounded-full" stroke={1} />
                <span className="sr-only">{t("common_delete")}</span>
              </Button>
            )}
          </AlertDialogTrigger>
        </TooltipTrigger>
        <TooltipContent className="bg-foreground text-background" side="left">
          {t("common_delete")}
        </TooltipContent>
      </Tooltip>
      <AlertDialogContent className="sm:max-w-[425px]">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-2xl font-bold">
            {title ? title : t("common_confirmDelete")}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <Separator />
        <p className="text-sm text-muted-foreground">
          {description ? description : t("descriptions_confirmDelete")}
        </p>
        <AlertDialogFooter>
          <div className="flex justify-end gap-2">
            <Button
              onClick={() => setOpen(false)}
              variant="outline"
              type="submit"
            >
              {t("common_cancel")}
            </Button>
            <Button variant="destructive" onClick={handleDelete} type="submit">
              {t("common_delete")}
            </Button>
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
