import { cn } from "@/lib/utils";

interface MediumHeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
}

export function MediumHeading({
  children,
  className,
  ...props
}: MediumHeadingProps) {
  return (
    <h2 className={cn("text-[1.1rem] font-semibold", className)} {...props}>
      {children}
    </h2>
  );
}
