import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useProductTour } from "../../../productTourContext/useProductTour";
// import { joyridePageMeta } from "./steps";
import { useJoyridePageMeta } from "./useJoyridePageMeta";

export function useJoyridePageState(page: string) {
  const { joyridePageMeta } = useJoyridePageMeta();
  const { joyrideState, setJoyrideState } = useProductTour();
  const { state } = useLocation() as { state?: { index: number } };

  useEffect(() => {
    if (!state?.index) return;
    if (joyrideState.tourActive) {
      const { endIdx, startIdx } = joyridePageMeta[page];
      setJoyrideState({
        run: true,
        stepIndex: state?.index === endIdx + 1 ? endIdx : startIdx,
      });
    }
  }, [
    joyridePageMeta,
    joyrideState.tourActive,
    page,
    setJoyrideState,
    state?.index,
  ]);
}
