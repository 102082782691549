import { useAuth } from "@/api/auth";
import { createContext, useCallback, useEffect, useState } from "react";
import { io, type Socket } from "socket.io-client";
import { SocketEvent, SocketProviderContextType } from "./types";

export const SocketProviderContext = createContext<SocketProviderContextType>(
  {} as SocketProviderContextType,
);

export function SocketProvider({ children }: { children: React.ReactNode }) {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { keycloak } = useAuth();

  const connectSocket = useCallback(async () => {
    if (!keycloak.authenticated) return null;

    try {
      // Attempt to refresh the token before connecting
      await keycloak.updateToken(5);
    } catch (error) {
      console.error("Failed to refresh token", error);
      return null;
    }

    const s = io(import.meta.env.VITE_API_URL, {
      extraHeaders: { Authorization: `Bearer ${keycloak.token}` },
    });

    s.on("connect", () => {
      // eslint-disable-next-line no-console
      console.info("socket connected");
      s.emit(SocketEvent.SUBSCRIBE_STATUS_UPDATES);
    });

    s.on("connect_error", async (error) => {
      console.error("Connection error:", error);
      if (!s.active) {
        try {
          await keycloak.updateToken(5);
        } catch (error) {
          keycloak.clearToken();
        }
      }
    });
    setSocket(s);
    return s;
  }, [keycloak]);

  const disconnectSocket = useCallback(() => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
      // eslint-disable-next-line no-console
      console.info("socket disconnected");
    }
  }, [socket]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isDemo = searchParams.get("demo") === "true";

    if (isDemo) {
      return;
    }

    let s: Socket | null = null;
    connectSocket()
      .then((newSocket) => {
        s = newSocket;
      })
      .catch(console.error);

    return () => {
      if (s) {
        s.close();
        // eslint-disable-next-line no-console
        console.info("socket closed");
      }
    };
  }, [keycloak.authenticated, connectSocket]);

  useEffect(() => {
    if (!keycloak.authenticated) {
      disconnectSocket();
    }
  }, [keycloak.authenticated, disconnectSocket]);

  return (
    <SocketProviderContext.Provider value={{ socket }}>
      {children}
    </SocketProviderContext.Provider>
  );
}
