import { useAuth } from "@/api/auth";
import { ThemeSwitch } from "@/components/layouts/NavigationLayout/components";
import { LanguageSelector } from "@/components/shared";
import SemiCircularProgressBar from "@/components/shared/SemiCircularProgressBar";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Badge,
  Button,
  buttonVariants,
} from "@/components/ui";
import { useIsDemo } from "@/hooks";
import { useGetMe } from "@/hooks/queries";
import { getInitials, getUploadStats } from "@/lib/utils";
import { RouteEnum } from "@/types";
import { IconLogout2 } from "@tabler/icons-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export function ProfileDetails() {
  const isDemo = useIsDemo();
  const { data: { uploadUsedBytes, uploadLimitBytes, isAdmin } = {} } =
    useGetMe({ enabled: true });
  const { t } = useTranslation();
  const { profile, keycloak } = useAuth();
  const logoutUrl = keycloak.createLogoutUrl({
    redirectUri: import.meta.env.VITE_APP_URL,
  });
  const { uploadUsedMb, uploadLimitMb, progress } = getUploadStats(
    uploadUsedBytes,
    uploadLimitBytes,
  );

  return (
    <>
      <div className="flex flex-col items-center gap-6 sm:flex-row sm:items-start">
        {/* Avatar */}
        <Avatar className="h-24 w-24">
          <AvatarImage className="object-cover" />
          <AvatarFallback className="bg-emerald-100 text-3xl text-zinc-900">
            {profile ? getInitials(profile.firstName, profile.lastName) : "..."}
          </AvatarFallback>
        </Avatar>
        {/* Details Container */}
        <div className="flex flex-col gap-y-6 divide-y sm:pl-6 [&>*]:pt-6">
          <div>
            {/* Name & Badge */}
            <div className="flex items-center gap-x-3 text-2xl font-semibold">
              {clsx(profile?.firstName, profile?.lastName)}
              {isAdmin && (
                <Badge variant="warning" className="rounded-full">
                  Admin
                </Badge>
              )}
            </div>
            {/* Email */}
            <div>{profile?.username}</div>
          </div>
          {/* Language & Theme */}
          <div>
            <div className="flex items-center">
              {t("common_language")}: <LanguageSelector />
            </div>
            <div className="flex items-center gap-4">
              {t("common_theme")}: <ThemeSwitch />
            </div>
          </div>
          {/* Upload Limit */}
          <div className="flex items-center justify-between">
            <div className="grid grid-cols-2 gap-x-4">
              <span>{`${t("common_uploadLimit")}: `}</span>
              <span className="font-semibold">
                {/* {uploadLimitMb ? `${uploadLimitMb.toFixed(1)} MB` : "(not set)"} */}
                {uploadLimitMb}
              </span>
              <span>{`${t("common_uploadUsed")}: `}</span>
              <span className="font-semibold">
                {/* {uploadUsedMb ? `${uploadUsedMb.toFixed(2)} MB` : "(not set)"} */}
                {uploadUsedMb}
              </span>
            </div>
            {progress && (
              <SemiCircularProgressBar
                colorBreakpoint={75}
                swapColors
                size={54}
                // showPercentage
                percentageSmall={false}
                progress={progress}
              />
            )}
          </div>
          {/* Logout & Update Password */}
          <div className="flex justify-center gap-3">
            <a
              className={buttonVariants({ variant: "outline" })}
              href={logoutUrl}
            >
              <IconLogout2 className="mr-3 h-4 w-4 rotate-180" stroke={1} />{" "}
              {t("common_logout")}
            </a>
            <Button
              disabled={isDemo}
              onClick={() =>
                keycloak.login({
                  action: "UPDATE_PASSWORD",
                  redirectUri: `${import.meta.env.VITE_APP_URL}${
                    RouteEnum.PROFILE
                  }/password-updated?sendEmail=true`,
                })
              }
            >
              {t("common_updatePassword")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
