import { Card, CardHeader, CardContent } from "@/components/ui";
import {
  IconInfoSquareRoundedFilled,
  IconRosetteDiscountCheckFilled,
} from "@tabler/icons-react";
import { Trans, useTranslation } from "react-i18next";

const UPLOAD_TIPS_KEYS = [
  "messages_uploadTipsGeocoordinates",
  "messages_uploadTipsFormatDates",
  "messages_uploadTipsValidateEmails",
] as const;

export function UploadTipsSection() {
  const { t } = useTranslation();
  return (
    <Card className="col-span-12 rounded-lg dark:bg-zinc-800">
      <CardHeader className="p-4 pb-8">
        <div className="flex items-center gap-x-3">
          <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-blue-50 text-blue-400">
            <IconInfoSquareRoundedFilled className="h-5 w-5" />
          </div>
          <div className="flex flex-col">
            <h3 className="font-bold">{t("titles_tipsForFutureUploads")}</h3>
            <p className="text-sm font-light">
              {t("descriptions_tipsForFutureUploads")}
            </p>
          </div>
        </div>
      </CardHeader>
      <CardContent className="px-4 pb-4">
        <div className="flex flex-col items-stretch gap-y-2">
          {UPLOAD_TIPS_KEYS.map((key) => (
            <div
              key={key}
              className="rounded-md bg-foreground/5 p-4 text-sm font-light"
            >
              <span className="inline-flex items-center gap-x-1">
                <IconRosetteDiscountCheckFilled className="mr-2 inline-flex h-4 w-4 shrink-0 text-blue-400" />
                <Trans i18nKey={key}>
                  <span className="font-semibold">{key}</span> {key}
                </Trans>
              </span>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
