import { LargeHeading } from "../../../shared";
import {
  MainContent,
  ContentWithoutSidebar,
  HeaderContent,
} from "../../../layouts";
import { UploadDialogDrawer } from "../components/UploadDialogDrawer";
import { useState } from "react";
import { useJoyridePageState } from "@/components/layouts/NavigationLayout/components/LeftNavBar/ProductTour/useJoyridePageState";
import { RouteEnum } from "@/types";
import { useTranslation } from "react-i18next";
import { DownloadGuidelinesButton } from "@/components/shared/UploadShipmentForm";
import { upperFirst } from "@/lib/utils";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "@/components/shared/ErrorFallback";
import { DataProcessingTable } from "../components/DataProcessingTable";

function Header() {
  const { t } = useTranslation();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(() => false);
  return (
    <>
      <div className="jr-overview-pg-upload-data">
        <LargeHeading>{upperFirst(t("common_dataUploads"))}</LargeHeading>
      </div>
      <div className="flex flex-wrap gap-2 md:flex-nowrap">
        <DownloadGuidelinesButton />
        <UploadDialogDrawer
          openState={uploadDialogOpen}
          setOpenState={setUploadDialogOpen}
        />
      </div>
    </>
  );
}

function Main() {
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div className="h-full w-full p-6">
          <ErrorFallback
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
            error={error}
            resetErrorBoundary={resetErrorBoundary}
          />
        </div>
      )}
    >
      <DataProcessingTable />
    </ErrorBoundary>
  );
}

export function UploadDataIndex() {
  useJoyridePageState(RouteEnum.UPLOADDATA);

  return (
    <>
      <HeaderContent>
        <Header />
      </HeaderContent>
      <ContentWithoutSidebar>
        <MainContent className="jr-status-pg-upload-data jr-dowload-report-pg-upload-data">
          <Main />
        </MainContent>
      </ContentWithoutSidebar>
    </>
  );
}
