import { RouteEnum } from "@/types";
import {
  IconChartBarPopular,
  IconFileDescription,
  IconListCheck,
  IconTruck,
  IconUpload,
  IconUserStar,
} from "@tabler/icons-react";

export type NavItem = {
  to: string;
  tLabelKey: string;
  icon: React.ReactNode;
  isVisible: boolean;
  className?: string;
};

export const navItemsData = (isDemo: boolean, isAdmin: boolean): NavItem[] => [
  {
    to: `${RouteEnum.UPLOADDATA}${isDemo ? "?demo=true" : ""}`,
    tLabelKey: "common_dataUpload",
    icon: <IconUpload className="h-6 w-6" stroke={1} />,
    isVisible: true,
    className: "jr-nav-pg-upload-data",
  },
  {
    to: `${RouteEnum.DASHBOARD}${isDemo ? "?demo=true" : ""}`,
    tLabelKey: "common_dashboard",
    icon: <IconChartBarPopular className="h-6 w-6" stroke={1} />,
    isVisible: true,
    className: "jr-nav-pg-dashboard",
  },
  {
    to: `${RouteEnum.SUPPLY_CHAIN}${isDemo ? "?demo=true" : ""}`,
    tLabelKey: "common_supplyChain",
    icon: <IconTruck className="h-6 w-6" stroke={1} />,
    isVisible: true,
    className: "jr-nav-pg-supply-chain",
  },
  {
    to: `${RouteEnum.REPORTING}${isDemo ? "?demo=true" : ""}`,
    tLabelKey: "common_reportCenter",
    icon: <IconFileDescription className="h-6 w-6" stroke={1} />,
    isVisible: true,
    className: "jr-nav-pg-reporting",
  },
  {
    to: `${RouteEnum.RECOMMENDATIONS}?transportMode=all&type=all${
      isDemo ? "&demo=true" : ""
    }`,
    tLabelKey: "common_recommendations",
    icon: <IconListCheck className="h-6 w-6" stroke={1} />,
    isVisible: true,
    className: "jr-nav-pg-recommendations",
  },
  {
    // to: `${RouteEnum.ADMIN_DASHBOARD}${isDemo ? "?demo=true" : ""}`,
    to: RouteEnum.ADMIN_DASHBOARD,
    tLabelKey: "common_adminDashboard",
    icon: <IconUserStar className="h-6 w-6" stroke={1} />,
    isVisible: isAdmin,
  },
];
