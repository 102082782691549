import axios, { AxiosError } from "axios";
import { keycloakInstance } from "@/api/auth/keycloakInstance";

const VITE_API_URL = import.meta.env.VITE_API_URL;
const VITE_STRAPI_URL = import.meta.env.VITE_STRAPI_URL;

export interface ErrorResponse {
  error?: string;
  message?: string;
  statusCode: number;
  details?: string;
}

interface StrapiErrorResponse {
  data: null;
  error: {
    status: number;
    name: string;
    message: string;
    details: unknown;
  };
}

const api = axios.create({
  baseURL: VITE_API_URL,
});

// add timeout
api.defaults.timeout = 2000000;

api.defaults.validateStatus = (status) => {
  return status >= 200 && status < 300; // default
};

api.interceptors.request.use(
  async (config) => {
    if (config.headers.Authorization) {
      try {
        const refreshed = await keycloakInstance.updateToken(5);
        if (refreshed) {
          config.headers.Authorization = `Bearer ${keycloakInstance.token}`;
        }
      } catch (error) {
        keycloakInstance.clearToken();
      }
    }
    return config;
  },
  (error) => console.error(error),
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isAxiosError<ErrorResponse, Record<string, unknown>>(error)) {
      if (error.response?.status === 401) {
        keycloakInstance
          .login({ redirectUri: window.location.href })
          .catch((err) => {
            console.error(err);
          });
      }
      return Promise.reject(error.response?.data);
    }
    return Promise.reject(error);
  },
);

const authHeader = () => ({
  Authorization: `Bearer ${keycloakInstance.token}`,
});

// Strapi API
const strapi = axios.create({
  baseURL: VITE_STRAPI_URL,
});

// One minute timeout
strapi.defaults.timeout = 60000;

strapi.defaults.validateStatus = (status) => {
  return status >= 200 && status < 300; // default
};

strapi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(
      (error as AxiosError<StrapiErrorResponse>).response?.data.error,
    );
  },
);

export { api, strapi, authHeader };
