export function getUploadStats(
  uploadUsedBytes?: number | null,
  uploadLimitBytes?: number | null,
) {
  const uploadUsedMb =
    typeof uploadUsedBytes === "number"
      ? (uploadUsedBytes / 1024 / 1024).toFixed(2) + " MB"
      : "(not set)";
  const uploadLimitMb =
    typeof uploadLimitBytes === "number"
      ? ((uploadLimitBytes ?? 0) / 1024 / 1024).toFixed(1) + " MB"
      : "(not set)";

  const progress =
    uploadLimitBytes === 0
      ? 100
      : uploadLimitBytes &&
          uploadUsedBytes &&
          typeof uploadLimitBytes === "number" &&
          typeof uploadUsedBytes === "number"
        ? Math.round((uploadUsedBytes / uploadLimitBytes) * 100)
        : undefined;

  return {
    uploadUsedMb,
    uploadLimitMb,
    progress,
  };
}
