declare global {
  interface Window {
    chatwootSettings: {
      hideMessageBubble: boolean;
      position: string;
      locale: string;
      type: string;
      launcherTitle: string;
      darkMode: "light" | "auto";
    };
    chatwootSDK: {
      run: (options: { websiteToken: string; baseUrl: string }) => void;
    };
    $chatwoot: {
      reset: () => void;
      toggle: () => void;
      setUser: (
        uniqueId: string,
        {
          email,
          name,
          avatar_url,
          phone_number,
        }: {
          email: string;
          name: string;
          avatar_url?: string;
          phone_number?: string;
        },
      ) => void;
    };
  }
}

import { useEffect } from "react";

export function Chatwoot() {
  useEffect(() => {
    window.chatwootSettings = {
      hideMessageBubble: true,
      position: "right",
      locale: "en",
      type: "expanded_bubble",
      launcherTitle: "Chat with us",
      darkMode: "auto",
    };

    (function (d, t) {
      const BASE_URL = import.meta.env.VITE_CHATWOOT_URL;
      const g = d.createElement(t) as HTMLScriptElement,
        s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      if (s.parentNode) {
        s.parentNode.insertBefore(g, s);
      }

      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: import.meta.env.VITE_CHATWOOT_WEBSITE_TOKEN,
          baseUrl: BASE_URL,
        });
      };
    })(document, "script");
  }, []);

  return null;
}
