import { Card, CardHeader, CardContent } from "@/components/ui";
import { IconSparkles, IconCircleCheckFilled } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const DATA_CLEANED_KEYS = [
  "messages_dataCleanedStandardization",
  "messages_dataCleanedGeocoordinates",
] as const;

export function CleanedDataSection() {
  const { t } = useTranslation();
  return (
    <Card className="col-span-12 rounded-lg dark:bg-zinc-800">
      <CardHeader className="p-4 pb-8">
        <div className="flex items-center gap-x-3">
          <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-success text-primary">
            <IconSparkles className="h-5 w-5" />
          </div>
          <div className="flex flex-col">
            <h3 className="font-bold">{t("titles_dataCleaned")}</h3>
            <p className="text-sm font-light">
              {t("descriptions_dataCleaned")}
            </p>
          </div>
        </div>
      </CardHeader>
      <CardContent className="px-4 pb-4">
        <div className="flex flex-col items-stretch gap-y-2">
          {DATA_CLEANED_KEYS.map((key) => (
            <div
              key={key}
              className="rounded-md bg-foreground/5 p-4 text-sm font-light"
            >
              <span className="inline-flex items-center gap-x-1">
                <IconCircleCheckFilled className="mr-2 inline-flex h-4 w-4 shrink-0 text-primary" />
                {t(key)}
              </span>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
