import { createContext, useContext } from "react";

export type FilterValues = Record<string, string[]>;

export interface FilterContextType {
  filterValues: FilterValues;
  handleValueChange: (paramName: string, value: string | undefined) => void;
  clearAllFilters: () => void;
  hasFiltersActive: boolean;
}

export const FilterContext = createContext<FilterContextType | undefined>(
  undefined,
);

export function useFilters() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error("useFilters must be used within a FilterProvider");
  }
  return context;
}
