import posthog from "posthog-js";
import { IS_PROD } from "./constants";

posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  autocapture: {
    dom_event_allowlist: ["click", "submit"], // DOM events from this list ['click', 'change', 'submit']
    // url_allowlist: ['posthog.com./docs/.*'], // strings or RegExps
    element_allowlist: ["button", "a", "form"], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
    // css_selector_allowlist: ['[ph-autocapture]'], // List of CSS selectors
  },
  opt_out_capturing_by_default: true,
  loaded: (posthog) => {
    if (IS_PROD) {
      posthog.opt_in_capturing();
    }
    posthog.set_config({
      persistence: "memory",
    });
  },
});
