import { cn } from "@/lib/utils";
import { Outlet } from "react-router-dom";
import { LegalPageBannerTitle } from "./NoNavLayout/components/LegalPageBannerTitle";
import { ScrollArea } from "../ui";
import { QueryErrorBoundary } from "../shared";
import { Suspense } from "react";
import { Loader } from "@/components/shared/loaders";

export function MainContentLayout({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "relative col-span-3 h-full max-h-screen max-w-[100vw] items-center p-3 lg:col-span-2 lg:p-8 lg:pt-12 xl:col-span-9",
        className,
      )}
    >
      <div
        className={
          "relative z-10 m-auto flex h-full w-full flex-col items-center justify-center space-y-6 rounded-lg sm:justify-start sm:p-6"
        }
      >
        {children}
      </div>
    </div>
  );
}

export function LegalPageContentLayout({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        "col-span-3 h-full content-start justify-start p-3 lg:col-span-2 lg:p-8 xl:col-span-9",
        className,
      )}
    >
      <div className="lg:px-10 xl:px-20">
        <Outlet />
      </div>
    </div>
  );
}

export function LegalPageLayout({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <LegalPageBannerTitle>{title}</LegalPageBannerTitle>
      <ScrollArea className="h-screen lg:px-10 xl:px-20">
        <QueryErrorBoundary>
          <Suspense
            fallback={
              <div className="flex h-screen w-full items-center justify-center">
                <Loader className="h-1/4" />
              </div>
            }
          >
            {children}
          </Suspense>
        </QueryErrorBoundary>
      </ScrollArea>
    </>
  );
}
