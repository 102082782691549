import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { InfoAlert } from "../../InfoAlert";
import { LiveChatButton } from "../../UploadShipmentForm";
import { IconAlertTriangle } from "@tabler/icons-react";
export function LimitExceededAlert({
  isUploadLimitExceeded,
  closeDialog,
}: {
  isUploadLimitExceeded: boolean;
  closeDialog?: () => void;
}) {
  const { t } = useTranslation();

  if (!isUploadLimitExceeded) return null;

  return (
    <motion.div layout>
      <InfoAlert
        title={t("titles_uploadLimitExceeded")}
        variant="nxtLogRed"
        IconComponent={IconAlertTriangle}
      >
        <p>{t("descriptions_uploadLimitExceeded")}</p>
        <div className="flex justify-end">
          <LiveChatButton
            size="sm"
            className="bg-accent"
            closeDialog={closeDialog}
          />
        </div>
      </InfoAlert>
    </motion.div>
  );
}
