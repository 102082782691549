import { Button } from "@/components/ui";
import { cn } from "@/lib/utils";
import { IconLayoutSidebarRightCollapse } from "@tabler/icons-react";
import { motion } from "framer-motion";

const MotionButton = motion(Button);

interface ExpandButtonProps {
  isExpanded: boolean;
  handleExpand: () => void;
  className?: string;
  hide?: boolean;
}

export function ExpandButton({
  isExpanded,
  handleExpand,
  className,
  hide = false,
}: ExpandButtonProps) {
  return hide ? (
    <div className="h-12 w-12" />
  ) : (
    <MotionButton
      layout
      variant="text"
      className={cn(
        "flex h-12 w-12 self-end rounded-sm p-0 text-background/50",
        { "w-6 !rotate-180": isExpanded },
        className,
      )}
      onClick={handleExpand}
    >
      <IconLayoutSidebarRightCollapse className="h-6 w-6" stroke={1} />
    </MotionButton>
  );
}
