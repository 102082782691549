import { UnsupportedFileAlert } from "./UnsupportedFileAlert";
import { FileTooBigAlert } from "./FileTooBigAlert";
// import { SheetPreview } from "../../UploadShipmentForm/SheetPreview";
import { UploadProgressBar } from "./UploadProgressBar";
import { LimitExceededAlert } from "./LimitExceededAlert";

type FileViewProps = {
  file?: File;
  uploadProgress: number;
  onDelete: (document: File) => void;
  maxSizeInMB: number;
  accept: string[];
  closeDialog?: () => void;
  isUploadLimitExceeded: boolean;
};

export function FileView({
  file,
  uploadProgress,
  onDelete,
  maxSizeInMB,
  accept,
  closeDialog,
  isUploadLimitExceeded,
}: FileViewProps) {
  if (!file) return null;
  const isFileSupported = accept.includes(
    `.${file.name.split(".").at(-1) || ""}`,
  );

  const isFileTooBig = file.size > maxSizeInMB * 1024 * 1024;

  // const showSheetPreview = isFileSupported && !isFileTooBig; /* && isIdle */

  return (
    <>
      <UploadProgressBar
        uploadProgress={uploadProgress}
        file={file}
        onDelete={onDelete}
      />
      {/* Temporarily disabled. Feature in development */}
      {/* {showSheetPreview && <SheetPreview file={file} />} */}
      <UnsupportedFileAlert isFileSupported={isFileSupported} accept={accept} />
      <FileTooBigAlert isFileTooBig={isFileTooBig} maxSizeInMB={maxSizeInMB} />
      <LimitExceededAlert
        isUploadLimitExceeded={isUploadLimitExceeded}
        closeDialog={closeDialog}
      />
    </>
  );
}
