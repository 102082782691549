import * as z from "zod";
import { ReportingStandards, ReportingTemplate } from "@/types";

export const createReportFormSchema = z.object({
  name: z
    .string()
    .min(3, { message: "Report name must be at least 3 characters" }),
  reportingStandards: z.nativeEnum(ReportingStandards),
  keepInformed: z.boolean().optional().default(false),
  // startDate: z.date(),
  // endDate: z.date(),
  template: z.nativeEnum(ReportingTemplate),
});
