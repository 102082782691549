import {
  addYears,
  eachMonthOfInterval,
  endOfMonth,
  endOfYear,
  format,
  isEqual,
  isFuture,
  startOfMonth,
  startOfToday,
  startOfYear,
  subYears,
} from "date-fns";
import * as React from "react";
import { Button, buttonVariants } from "@/components/ui";
import { cn, getDateLocale } from "@/lib/utils";
import { RequiredDateRange } from "@/components/layouts/NavigationLayout/dateRangeContext/DateRangeContextProvider2";
import { PopoverClose } from "@/components/ui";
import { Month, MonthGrid } from ".";
import { useTranslation } from "react-i18next";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";

function getStartOfCurrentMonth() {
  return startOfMonth(startOfToday());
}

function getEndOfCurrentMonth() {
  return endOfMonth(startOfToday());
}

interface MonthRangePickerPrimitiveProps {
  currentDate: Date;
  yearsDisplayed?: 1 | 2;
  initialRange?: RequiredDateRange;
  onApply: ({ from, to }: RequiredDateRange) => void;
}

export function MonthRangePickerPrimitive({
  currentDate,
  initialRange = { from: getStartOfCurrentMonth(), to: getEndOfCurrentMonth() },
  yearsDisplayed = 1,
  onApply,
}: MonthRangePickerPrimitiveProps) {
  const { t } = useTranslation();
  const [selectedRange, setSelectedRange] = React.useState<{
    from: Date;
    to?: Date;
  }>(() => initialRange);
  const [displayedYear, setDisplayedYear] = React.useState(() =>
    startOfYear(selectedRange.to ?? startOfYear(currentDate)),
  );
  const displayedYearMonths = eachMonthOfInterval({
    start: displayedYear,
    end: endOfYear(displayedYear),
  });

  function getDisplayedPreviousYearMonths() {
    const displayedPrevYear = subYears(displayedYear, 1);
    return eachMonthOfInterval({
      start: displayedPrevYear,
      end: endOfYear(displayedPrevYear),
    });
  }

  function goToPreviousYear() {
    setDisplayedYear((prev) => subYears(prev, 1));
  }

  function goToNextYear() {
    setDisplayedYear((prev) => addYears(prev, 1));
  }

  const { to, from } = selectedRange;

  return (
    <div className="flex flex-col gap-y-2 bg-gray-50 p-2 dark:bg-zinc-900">
      {/* Header */}
      <div className="relative flex w-full items-center justify-evenly bg-background p-3 shadow-custom">
        <div
          className="text-sm font-medium"
          aria-live="polite"
          id="month-picker"
        >
          {format(subYears(displayedYear, 1), "yyyy")}
        </div>
        <div
          className="text-sm font-medium"
          aria-live="polite"
          id="month-picker"
        >
          {format(displayedYear, "yyyy")}
        </div>
        <div className="absolute left-0 top-0 flex h-full w-full items-center">
          <button
            name="previous-year"
            aria-label="Go to previous year"
            className={cn(
              buttonVariants({ variant: "outline" }),
              "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
              "absolute left-1",
            )}
            type="button"
            onClick={goToPreviousYear}
          >
            <IconArrowLeft className="h-4 w-4" stroke={1} />
          </button>
          <button
            name="next-year"
            aria-label="Go to next year"
            className={cn(
              buttonVariants({ variant: "outline" }),
              "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
              "absolute right-1 disabled:invisible",
            )}
            type="button"
            disabled={isFuture(addYears(displayedYear, 1))}
            onClick={goToNextYear}
          >
            <IconArrowRight className="h-4 w-4" stroke={1} />
          </button>
        </div>
      </div>
      {/* Body */}
      <div>
        <div className="flex gap-x-2">
          {yearsDisplayed > 1 && (
            <MonthGrid>
              {getDisplayedPreviousYearMonths().map((month) => (
                <Month
                  key={month.toString()}
                  month={month}
                  selectedRangeFrom={from}
                  selectedRangeTo={to}
                  currentDate={currentDate}
                  setCurrentRange={setSelectedRange}
                />
              ))}
            </MonthGrid>
          )}
          <MonthGrid>
            {displayedYearMonths.map((month) => (
              <Month
                key={month.toString()}
                month={month}
                selectedRangeFrom={from}
                selectedRangeTo={to}
                currentDate={currentDate}
                setCurrentRange={setSelectedRange}
              />
            ))}
          </MonthGrid>
        </div>
      </div>
      {/* Footer */}
      <div className="flex w-full items-center justify-center gap-x-2 bg-transparent p-3 text-2xs">
        <span>{format(from, "d MMM yyyy", { locale: getDateLocale() })}</span>
        <span>-</span>
        <span className="min-w-14">
          {to ? format(to, "d MMM yyyy", { locale: getDateLocale() }) : null}
        </span>
        <PopoverClose asChild>
          <Button
            size="icon"
            className="h-6 w-auto px-2 text-xs"
            onClick={() => (to ? onApply({ from, to }) : null)}
            disabled={
              !to ||
              (isEqual(from, initialRange.from) && isEqual(to, initialRange.to))
            }
          >
            <span>{t("common_apply")}</span>
          </Button>
        </PopoverClose>
      </div>
    </div>
  );
}
