import { useIsDemo } from "@/hooks";
import { NavLink } from "react-router-dom";
import { NavButton } from "..";
import { useGetMe } from "@/hooks/queries";
import { cn } from "@/lib/utils";
import { navItemsData } from "./navItemsData";

export function NavItems({
  isExpanded,
  closeMenu,
}: {
  isExpanded: boolean;
  closeMenu?: () => void;
}) {
  const { data: { isAdmin = false } = {} } = useGetMe({ enabled: true });
  const isDemo = useIsDemo();
  return navItemsData(isDemo, isAdmin)
    .filter(({ isVisible }) => isVisible)
    .map(({ to, tLabelKey, icon, className }) => (
      <NavLink
        to={to}
        key={to}
        className={cn("w-fit", className)}
        onClick={closeMenu}
      >
        {({ isActive }) => (
          <NavButton {...{ isActive, isExpanded, icon, tLabelKey }} />
        )}
      </NavLink>
    ));
}
