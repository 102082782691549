import * as z from "zod";

export const createAccountFormSchema = z
  .object({
    firstName: z
      .string()
      .min(1, { message: "First name must be at least one character long" }),
    lastName: z
      .string()
      .min(1, { message: "Last name must be at least one character long" }),
    email: z.string().email(),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .regex(/[a-z]/, "Password must contain at least one lowercase letter")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[0-9]/, "Password must contain at least one number")
      .regex(
        /[^A-Za-z0-9]/,
        "Password must contain at least one special character",
      ),
    confirmPassword: z.string(),
    termsAndConditions: z.boolean().refine((val) => val, {
      message: "You must agree to the terms and conditions to continue",
    }),
  })
  .refine((data) => data.password !== data.email, {
    message: "Password cannot be the same as email",
    path: ["password"],
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });
