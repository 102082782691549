import { useMutation } from "@tanstack/react-query";
import { api } from "@/api";
import { z } from "zod";
import { createAccountFormSchema } from "@/components/pages/HomePage/components/createAccountFormSchema";
import { useToast } from "@/components/ui";
import { IconAlertTriangle, IconCircleCheck } from "@tabler/icons-react";

interface RegisterUserResp {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  "not-before-policy": number;
  session_state: string;
  scope: string;
}

const registerUser = async ({
  data,
}: MutationVariables): Promise<RegisterUserResp> => {
  const response = await api.post("/auth/register", data);
  return response.data as RegisterUserResp;
};

interface MutationVariables {
  data: Omit<
    z.infer<typeof createAccountFormSchema>,
    "confirmPassword" | "termsAndConditions"
  >;
}
export const useRegisterUser = () => {
  const { toast } = useToast();
  return useMutation({
    mutationFn: (mutationVariables: MutationVariables) =>
      registerUser(mutationVariables),
    onSuccess: () => {
      toast({
        description: (
          <div className="flex items-center gap-3">
            <IconCircleCheck className="h-5 w-5 shrink-0" />
            <span>Account created successfully</span>
          </div>
        ),
        variant: "success",
      });
    },
    onError: (error) => {
      toast({
        description: (
          <div className="flex items-center gap-3">
            <IconAlertTriangle className="h-5 w-5 shrink-0" />
            <span>{error.message}</span>
          </div>
        ),
        variant: "error",
      });
    },
  });
};
