import { useContext } from "react";
import { ProductTourContext } from ".";

export function useProductTour() {
  const context = useContext(ProductTourContext);
  if (context === null) {
    throw new Error(
      "useProductTour must be used within a ProductTourContextProvider",
    );
  }
  return context;
}
