import {
  Sheet,
  SheetContent,
  // SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  Button,
} from "@/components/ui";
import { NavItems } from ".";
import { useState } from "react";
import { IconMenu2 } from "@tabler/icons-react";

export function MobileNav() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Sheet open={menuOpen} onOpenChange={setMenuOpen}>
      <SheetTrigger asChild>
        <Button
          className="grid h-8 w-8 place-content-center rounded-full p-2 md:hidden"
          variant="outline"
        >
          <IconMenu2 className="h-6 w-6" />
          <span className="sr-only">Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="w-auto bg-zinc-700">
        <SheetHeader>
          <SheetTitle className="text-white">Menu</SheetTitle>
        </SheetHeader>
        <div className="flex flex-col gap-y-1 pt-6">
          <NavItems isExpanded closeMenu={() => setMenuOpen(false)} />
        </div>
      </SheetContent>
    </Sheet>
  );
}
