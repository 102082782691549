import { useIsOffline } from "@/hooks";
import posthog from "posthog-js";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CookieConsentBanner } from "../CookieConsentBanner";

export function RootLayout() {
  useIsOffline();

  const { pathname } = useLocation();
  useEffect(() => {
    posthog.capture("$pageview", { $pathname: pathname });
  }, [pathname]);

  return (
    <>
      <Outlet />
      <CookieConsentBanner />
    </>
  );
}
