import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { buttonVariants } from ".";
import { LucideLoaderCircle } from "@/assets/icons";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    const { children, isLoading, disabled, ...rest } = props;
    return (
      <Comp
        className={cn(
          "whitespace-nowrap",
          buttonVariants({ variant, size, className }),
        )}
        ref={ref}
        disabled={isLoading || disabled}
        {...rest}
      >
        {isLoading ? (
          <LucideLoaderCircle className="mr-2 h-4 w-4 animate-spin" />
        ) : null}
        {children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button };
