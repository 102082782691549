import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  ButtonSize,
  ButtonVariants,
  Separator,
} from "@/components/ui";
import { useState } from "react";
import { CreateReportForm } from "./CreateReportForm";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { IconFileTypeXls } from "@tabler/icons-react";

export function CreateReportDialog({
  trigger,
  className,
  size,
  variant,
  openState,
  onOpenStateChange,
}: {
  trigger?: React.ReactNode;
  className?: string;
  size?: ButtonSize;
  variant?: ButtonVariants;
  openState?: boolean;
  onOpenStateChange?: (open: boolean) => void;
}) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const dialogOpen = openState ? openState : open;
  const setDialogOpen = onOpenStateChange ? onOpenStateChange : setOpen;

  return (
    <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <AlertDialogTrigger asChild>
        {trigger || (
          <Button
            variant={variant ? variant : "default"}
            className={cn("whitespace-nowrap", className)}
            size={size ? size : isDesktop ? "default" : "sm"}
          >
            <IconFileTypeXls className="mr-2 h-5 w-5" stroke={1} />
            {t("common_createReport")}
          </Button>
        )}
      </AlertDialogTrigger>
      <AlertDialogContent className="sm:max-w-[425px]">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-2xl font-bold">
            {t("common_createReport")}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <Separator />
        <CreateReportForm
          onDone={() => {
            queryClient
              .invalidateQueries({
                queryKey: ["reports"],
              })
              .catch(console.error);
            setDialogOpen(false);
          }}
        />
      </AlertDialogContent>
    </AlertDialog>
  );
}
