export const ENV_URLS = {
  PROD: "https://app.nxtlog.io",
  DEV: "https://dev-app.nxtlog.io",
  TEST: "https://test-app.nxtlog.io",
  LOCAL: "http://localhost:5173",
  ASHY_OCEAN: "https://ashy-ocean-09b847503.4.azurestaticapps.net",
};

export const IS_LOCAL = import.meta.env.VITE_APP_URL === ENV_URLS.LOCAL;

export const IS_DEV = [
  ENV_URLS.DEV,
  ENV_URLS.LOCAL,
  ENV_URLS.ASHY_OCEAN,
].includes(import.meta.env.VITE_APP_URL);

export const IS_PROD = import.meta.env.VITE_APP_URL === ENV_URLS.PROD;

export const IS_TEST = import.meta.env.VITE_APP_URL === ENV_URLS.TEST;

export const DATA_TEMPLATE_URL =
  // "https://nxtlogmdevsa.blob.core.windows.net/public-files/input_file_example_1_row.xlsx";
  "https://nxtlogmdevsa.blob.core.windows.net/public-files/NxtLog_Sample_Data.xlsx";

export const GUIDELINES_URL =
  "https://nxtlogmdevsa.blob.core.windows.net/public-files/NxtLog_Data_Upload_Guidelines.pdf";

export const DEMO_USER_ID = "123";

export const MANDATORY_FIELDS = [
  "transport_mode_1",
  "reference_date",
  "shipper_city",
  "shipper_country",
  "consignee_city",
  "consignee_country",
  "gross_weight_kg",
];

export const FILTER_TRANSLATION_KEYS = {
  modes: "common_transportMode",
  shipperNames: "common_shipperName",
  consigneeNames: "common_consigneeName",
} as const;
