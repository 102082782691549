import { Button } from "@/components/ui";
import { RouteEnum } from "@/types";
import { useEffect } from "react";
import { LoginLink } from "../LoginLink";
import { useIsDemo } from "@/hooks";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "@/api/auth";
import { useTranslation } from "react-i18next";
import GradualSpacing from "@/components/ui/gradual-spacing";
import { AnimatePresence, motion } from "framer-motion";

export function HomePageTitleAndActions() {
  const isDemo = useIsDemo();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    keycloak: { authenticated },
  } = useAuth();

  useEffect(() => {
    if (isDemo) {
      navigate(`${RouteEnum.DASHBOARD}?demo=true`);
    }
  }, [isDemo, navigate]);

  if (authenticated && !isDemo) {
    return <Navigate to={RouteEnum.INITIAL_UPLOAD} />;
  }

  const actions = (
    <motion.div
      key="actions"
      className="flex w-full flex-wrap items-center justify-center divide-x pt-5 sm:justify-start"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: 0.6 }}
    >
      <div>
        <Link
          to={RouteEnum.CREATE_ACCOUNT}
          className="pr-4 font-semibold text-primary hover:underline"
        >
          {t("common_createAccount")}
        </Link>
      </div>
      <div>
        <LoginLink className="px-4 text-base font-semibold" />
      </div>
      <div>
        <Button
          onClick={() =>
            window.location.assign(
              `${import.meta.env.VITE_APP_URL}${RouteEnum.DASHBOARD}?demo=true`,
            )
          }
          variant="link"
          className="px-4 text-base font-semibold"
        >
          {t("common_viewDemo")}
        </Button>
      </div>
    </motion.div>
  );

  const subHeading = (
    <motion.div
      key="subHeading"
      className="flex w-full flex-col"
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4, delay: 0.5 }}
    >
      <h2 className="border-b py-3 text-center text-lg font-light text-muted-foreground sm:text-left">
        {t("titles_homePage-subtitle")}
      </h2>
    </motion.div>
  );

  return (
    <div className="m-auto flex max-w-xl flex-col p-2">
      <GradualSpacing className="border-b pb-4 text-center text-4xl font-bold sm:text-left sm:text-title">
        {t("titles_homePage-mainTitle")}
      </GradualSpacing>
      <AnimatePresence>
        {subHeading}
        {actions}
      </AnimatePresence>
    </div>
  );
}
