import { IS_PROD } from "@/lib/constants";
import { usePostHog } from "posthog-js/react";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocalStorage } from "usehooks-ts";
import { z } from "zod";

const cookieConsentSchema = z.object({
  consent: z.enum([
    "undecided",
    "allAccepted",
    "allRejected",
    "partlyAccepted",
  ]),
  functional: z.boolean(),
  analytics: z.boolean(),
});

interface CookieConsentProviderContextType {
  acceptAllCookies: () => void;
  rejectAllCookies: () => void;
  acceptFunctional: () => void;
  rejectFunctional: () => void;
  acceptAnalytics: () => void;
  rejectAnalytics: () => void;
  cookieConsent: z.infer<typeof cookieConsentSchema>;
  showBanner: boolean;
  closeBanner: () => void;
}

export const CookieConsentContext =
  createContext<CookieConsentProviderContextType>(
    {} as CookieConsentProviderContextType,
  );

export function CookieConsentProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [cookieConsent, setCookieConsent] = useLocalStorage<
    z.infer<typeof cookieConsentSchema>
  >("cookieConsent", {
    consent: "undecided",
    functional: false,
    analytics: false,
  });

  const posthog = usePostHog();

  const { success } = useMemo(
    () => cookieConsentSchema.safeParse(cookieConsent),
    [cookieConsent],
  );

  const [showBanner, setShowBanner] = useState(
    () => cookieConsent.consent === "undecided",
  );

  useEffect(() => {
    if (!success) {
      setCookieConsent({
        consent: "undecided",
        functional: false,
        analytics: false,
      });
    }
  }, [setCookieConsent, success]);

  const { analytics } = cookieConsent;

  useEffect(() => {
    if (IS_PROD) {
      if (analytics) {
        posthog.set_config({
          persistence: "localStorage+cookie",
        });
      } else {
        posthog.set_config({
          persistence: "memory",
        });
      }
    }
  }, [analytics, posthog]);

  const closeBanner = useCallback(() => {
    setShowBanner(false);
  }, []);

  const acceptAllCookies = useCallback(() => {
    setCookieConsent({
      consent: "allAccepted",
      functional: true,
      analytics: true,
    });
  }, [setCookieConsent]);

  const rejectAllCookies = useCallback(() => {
    setCookieConsent({
      consent: "allRejected",
      functional: false,
      analytics: false,
    });
  }, [setCookieConsent]);

  const acceptFunctional = useCallback(() => {
    setCookieConsent((prev) => ({
      ...prev,
      consent: "partlyAccepted",
      functional: true,
    }));
  }, [setCookieConsent]);

  const rejectFunctional = useCallback(() => {
    setCookieConsent((prev) => ({
      ...prev,
      consent: "partlyAccepted",
      functional: false,
    }));
  }, [setCookieConsent]);

  const acceptAnalytics = useCallback(() => {
    setCookieConsent((prev) => ({
      ...prev,
      consent: "partlyAccepted",
      analytics: true,
    }));
  }, [setCookieConsent]);

  const rejectAnalytics = useCallback(() => {
    setCookieConsent((prev) => ({
      ...prev,
      consent: "partlyAccepted",
      analytics: false,
    }));
  }, [setCookieConsent]);

  const value = useMemo(
    () => ({
      showBanner,
      closeBanner,
      cookieConsent,
      acceptAllCookies,
      rejectAllCookies,
      acceptFunctional,
      rejectFunctional,
      acceptAnalytics,
      rejectAnalytics,
    }),
    [
      showBanner,
      closeBanner,
      cookieConsent,
      acceptAllCookies,
      rejectAllCookies,
      acceptFunctional,
      rejectFunctional,
      acceptAnalytics,
      rejectAnalytics,
    ],
  );

  return (
    <CookieConsentContext.Provider value={value}>
      {children}
    </CookieConsentContext.Provider>
  );
}
