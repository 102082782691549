import { useAuth } from "@/api/auth";
import { DEMO_USER_ID } from "@/lib/constants";
import { RouteEnum } from "@/types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

export function useOnFirstLogin(callback: () => void) {
  const { pathname } = useLocation();
  const {
    keycloak: { subject },
  } = useAuth();
  const [hasPreviouslyLoggedIn, setHasPreviouslyLoggedIn] = useLocalStorage<
    string[]
  >("hasPreviouslyLoggedIn", [DEMO_USER_ID]);

  useEffect(() => {
    if (
      !subject ||
      subject === DEMO_USER_ID ||
      pathname !== (RouteEnum.DASHBOARD as string)
    ) {
      return;
    }
    const isFirstLogin = !hasPreviouslyLoggedIn.includes(subject);
    if (isFirstLogin) {
      setHasPreviouslyLoggedIn([...hasPreviouslyLoggedIn, subject]);
      callback();
    }
  }, [
    callback,
    subject,
    hasPreviouslyLoggedIn,
    setHasPreviouslyLoggedIn,
    pathname,
  ]);
}
