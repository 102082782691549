import { Alert, AlertTitle, AlertDescription, Button } from "@/components/ui";
import { IconDownload } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export function InvalidShipmentsAlert({
  invalidShipments,
  downloadInvalidShipments,
}: {
  invalidShipments: number | null;
  downloadInvalidShipments: (() => void) | null;
}) {
  const { t } = useTranslation();
  return (
    <Alert variant="nxtLogRed" className="col-span-12">
      <AlertTitle>
        {t("titles_nrOfInvalidShipmentsAlert", {
          invalidShipments,
        })}
      </AlertTitle>
      <AlertDescription>
        <div className="flex flex-col gap-y-4 font-light">
          <span>{t("descriptions_nrOfInvalidShipmentsAlert")}</span>
          {downloadInvalidShipments && (
            <div>
              <Button
              variant="outline"
              className="bg-gray-50 dark:bg-zinc-800"
              onClick={downloadInvalidShipments}
            >
              <IconDownload className="mr-2 h-3.5 w-3.5" />
              <span>{t("common_downloadInvalidShipments")}</span>
            </Button>
          </div>
          )}
        </div>
      </AlertDescription>
    </Alert>
  );
}
