import { useState } from "react";
import { FileUpload } from "../FileUpload";
import { InfoAlert } from "@/components/shared";
import { Button } from "@/components/ui";
import { useUploadShipmentFile } from "@/hooks/mutations";
import { motion } from "framer-motion";
import { UploadErrorResponse, HelpButtons, UploadPendingResponse } from ".";
import { Link } from "react-router-dom";
import { cn, upperFirst } from "@/lib/utils";
import { Trans, useTranslation } from "react-i18next";
import { UploadCompleteResponse } from "./UploadCompleteResponse";
import { GUIDELINES_URL } from "@/lib/constants";
import { useIsUploadLimitExceeded } from "@/hooks";
import { ChooseFileView } from "../FileUpload/components/ChooseFileView";
import { FileView } from "../FileUpload/components/FileView";

const allowedFileExtensions = [".xlsx"];
const MAX_FILE_SIZE_IN_MB = 100;

interface UploadShipmentFormProps {
  onMutationSuccess?: () => void;
  onUploadComplete?: () => void;
  onCancel?: () => void;
}

export function UploadShipmentForm({
  onMutationSuccess,
  onUploadComplete,
  onCancel,
}: UploadShipmentFormProps) {
  const { t } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const {
    mutate,
    data,
    error,
    isError,
    isPending,
    isSuccess,
    reset /* isIdle */,
  } = useUploadShipmentFile({ onSuccess: onMutationSuccess });
  const [file, setFile] = useState<File>();
  const isUploadLimitExceeded = useIsUploadLimitExceeded(file?.size);
  // const isUploadLimitExceeded = true;

  const onReset = () => {
    setFile(undefined);
    reset();
  };

  const handleCancel = () => {
    onReset();
    onCancel?.();
  };

  const onChange = (files: FileList) => {
    if (!files[0]) return;
    const file = files[0];
    setFile(file);
  };

  const readyInfoBox = (
    <InfoAlert
      variant="nxtLogYellow"
      title={upperFirst(t("common_dataUploadGuidelines"))}
    >
      <div className="flex flex-col space-y-2">
        <Trans i18nKey="descriptions_dataImportAlert">
          <p>
            Please note that we currently only support data uploads that
            strictly follow our guidelines. Kindly{" "}
            <Link
              to={GUIDELINES_URL}
              target="_blank"
              className="font-semibold underline"
            >
              download our upload guidelines document
            </Link>{" "}
            and adjust your file and data accordingly.
          </p>
        </Trans>
      </div>
    </InfoAlert>
  );

  const cancelButton = (
    <Button variant="outline" onClick={handleCancel} disabled={isPending}>
      {t("common_cancel")}
    </Button>
  );

  const uploadButton = (
    <Button
      disabled={
        !file ||
        !allowedFileExtensions.includes(
          `.${file.name.split(".").at(-1) || ""}`,
        ) ||
        file.size > MAX_FILE_SIZE_IN_MB * 1024 * 1024 ||
        isError ||
        isPending ||
        isUploadLimitExceeded
      }
      onClick={() => (file ? mutate({ file, setUploadProgress }) : null)}
    >
      {t("common_upload")}
    </Button>
  );

  const actions = (
    <motion.div
      layout
      className={cn(
        "sticky bottom-0 flex w-full justify-end gap-2 bg-background/50 py-2 backdrop-blur",
        {
          hidden: isSuccess,
        },
      )}
    >
      <>
        {cancelButton}
        {uploadButton}
      </>
    </motion.div>
  );

  return (
    <motion.div layout className="mt-2 w-full space-y-4 overflow-auto">
      <FileUpload
        isFileSelected={Boolean(file)}
        fileView={
          <FileView
            file={file}
            uploadProgress={uploadProgress}
            onDelete={() => setFile(undefined)}
            maxSizeInMB={MAX_FILE_SIZE_IN_MB}
            accept={allowedFileExtensions}
            closeDialog={onCancel}
            isUploadLimitExceeded={isUploadLimitExceeded}
          />
        }
        chooseFileView={
          <ChooseFileView
            className="bg-background shadow-custom dark:border"
            readyInfoBox={readyInfoBox}
            maxSizeInMB={MAX_FILE_SIZE_IN_MB}
            accept={allowedFileExtensions}
            onChange={onChange}
            name="file"
          />
        }
      />
      <UploadPendingResponse isPending={isPending} />
      <UploadErrorResponse error={error} onReset={onReset} />
      <UploadCompleteResponse
        data={data}
        onUploadComplete={onUploadComplete}
        onReset={onReset}
      />
      {actions}
      <HelpButtons closeDialog={onCancel} />
    </motion.div>
  );
}
