import { FormatNumber, InfoHoverButton } from "@/components/shared";
import SemiCircularProgressBar from "@/components/shared/SemiCircularProgressBar";
import { Card, CardHeader, CardContent, CardFooter } from "@/components/ui";
import { t } from "i18next";

export type CardData = {
  title: string;
  translatedTitle: string;
  value: number | null;
  percentage: number | null;
};

type CardsProps = {
  cardsData: CardData[];
  dataScore: number | null;
  totalShipments: number;
};

export function Cards({ cardsData, dataScore, totalShipments }: CardsProps) {
  return (
    <>
      {cardsData
        .filter(
          (card) =>
            typeof card.value === "number" &&
            typeof card.percentage === "number",
        )
        .map(({ title, translatedTitle, value, percentage }) => (
          <Card
            key={title}
            className="col-span-12 flex flex-col justify-between rounded-lg dark:bg-zinc-800 lg:col-span-4"
          >
            <CardHeader className="p-4 pb-0 text-sm">
              <div className="flex items-start">
                <h3>{translatedTitle}</h3>
              </div>
            </CardHeader>
            <CardContent className="px-4 pb-0 text-3xl font-extrabold">
              <FormatNumber suffix="%">{percentage!}</FormatNumber>
            </CardContent>
            <CardFooter className="px-4 pb-4 text-sm font-light">
              <>
                <span className="font-bold">
                  <FormatNumber>{value!}</FormatNumber>
                </span>
                &nbsp;{t("common_outOf")}&nbsp;
                <span className="font-bold">{totalShipments}</span>
              </>
            </CardFooter>
          </Card>
        ))}

      {/* Data quality score */}
      {dataScore && (
        <Card className="col-span-12 flex flex-col justify-between rounded-lg dark:bg-zinc-800 lg:col-span-4">
          <CardHeader className="p-4 pb-0 text-sm">
            <div className="flex items-start justify-between">
              <h3>{t("common_dataQualityScore")}</h3>
              <InfoHoverButton>{t("descriptions_dataScore")}</InfoHoverButton>
            </div>
          </CardHeader>
          <CardContent className="px-4 pb-0 text-3xl font-extrabold">
            <div className="flex items-center justify-center pt-4">
              <SemiCircularProgressBar
                size={90}
                progress={dataScore * 100}
                showPercentage
              />
            </div>
          </CardContent>
          <CardFooter className="px-4 pb-4 text-sm font-light">
            {/* TODO: Add change in quality score once available */}
            {/* <IconSparkles className="mr-2 h-3.5 w-3.5 text-primary" />
            <span className="font-bold">+14%</span> &nbsp; after data cleanup */}
          </CardFooter>
        </Card>
      )}
    </>
  );
}
