import { CommandItem } from "@/components/ui";
import { Link } from "react-router-dom";
import i18n from "@/i18next/i18n";
import { useRef } from "react";

type NavItem = {
  to: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: Record<string, any>;
  icon: React.ReactNode;
  tLabelKey: string;
  isVisible: boolean;
  target?: "_blank" | "_self" | "_parent" | "_top";
  rel?: "noreferrer";
  download?: boolean;
};

export const PageLink = ({
  navItem,
  closeDialog,
}: {
  navItem: NavItem;
  closeDialog: () => void;
}) => {
  const ref = useRef<HTMLAnchorElement>(null);
  return (
    <CommandItem
      asChild
      key={navItem.to}
      onSelect={() => {
        ref.current?.click();
        closeDialog();
      }}
    >
      <Link
        ref={ref}
        to={navItem.to}
        state={navItem.state}
        className="flex items-center gap-2"
      >
        {navItem.icon}
        {i18n.t(navItem.tLabelKey)}
      </Link>
    </CommandItem>
  );
};
