import { Button, buttonVariants } from "@/components/ui";
import { ButtonSize, ButtonVariants } from "@/components/ui";
import { useToggleChat } from "@/hooks";
import { DATA_TEMPLATE_URL, GUIDELINES_URL } from "@/lib/constants";
import { cn, upperFirst } from "@/lib/utils";
import {
  Icon,
  IconDownload,
  IconFileTypePdf,
  IconMessageCircle,
  IconProps,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function HelpButtons({ closeDialog }: { closeDialog?: () => void }) {
  const { t } = useTranslation();
  return (
    <motion.div className="space-y-2 pt-8" layout>
      <div className="flex flex-wrap items-center gap-2">
        <span className="font-semibold">{t("common_needSomeHelp")}</span>
        <div className="flex items-center gap-2">
          <DownloadSampleDataButton size="sm" />
          <DownloadGuidelinesButton size="sm" />
          <LiveChatButton size="sm" closeDialog={closeDialog} />
        </div>
      </div>
    </motion.div>
  );
}

export function DownloadSampleDataButton({
  size = "default",
}: {
  size?: "sm" | "default" | "lg" | "icon";
}) {
  const { t } = useTranslation();
  return (
    <Link
      to={DATA_TEMPLATE_URL}
      target="_blank"
      className={cn(buttonVariants({ variant: "outline", size }))}
      rel="noreferrer"
      download
    >
      <IconDownload className="mr-2 h-5 w-5 text-primary" stroke={1} />
      <span className="whitespace-nowrap">{t("common_sampleData")}</span>
    </Link>
  );
}

export function DownloadGuidelinesButton({
  size = "default",
}: {
  size?: "sm" | "default" | "lg" | "icon";
}) {
  const { t } = useTranslation();
  return (
    <Link
      to={GUIDELINES_URL}
      target="_blank"
      className={cn(buttonVariants({ variant: "outline", size }))}
      rel="noreferrer"
      download
    >
      <IconFileTypePdf className="mr-2 h-5 w-5" stroke={1} />
      <span className="whitespace-nowrap">
        {upperFirst(t("common_guidelines"))}
      </span>
    </Link>
  );
}

export function LiveChatButton({
  size = "default",
  className,
  label,
  variant,
  iconClassName,
  IconComponent,
  closeDialog,
}: {
  size?: ButtonSize;
  className?: string;
  label?: string;
  variant?: ButtonVariants;
  iconClassName?: string;
  closeDialog?: () => void;
  IconComponent?: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
}) {
  const { t } = useTranslation();
  const toggleChat = useToggleChat();
  const Icon = IconComponent ?? IconMessageCircle;
  return (
    <Button
      className={className}
      variant={variant ?? "outline"}
      size={size}
      onClick={() => {
        toggleChat();
        closeDialog?.();
      }}
    >
      <Icon
        className={cn("mr-2 h-5 w-5 text-primary", iconClassName)}
        stroke={1}
      />
      <span className="whitespace-nowrap">{label ?? t("common_liveChat")}</span>
    </Button>
  );
}
