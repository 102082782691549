import { useSuspenseQuery } from "@tanstack/react-query";
import { useIsDemo } from "..";
import mockDataProcessingData from "@/components/pages/UploadData/mockDataProcessingData";
import { api, authHeader } from "@/api";
import { ShipmentsFileRecord } from "./types";

const getShipmentsFile = async (fileId: number) => {
  const response = await api.get<ShipmentsFileRecord>(
    `/shipments/files/${fileId}`,
    {
      headers: { ...authHeader() },
    },
  );
  return response.data;
};

export function useGetShipmentsFile({ fileId }: { fileId: number }) {
  const isDemo = useIsDemo();

  return useSuspenseQuery<ShipmentsFileRecord>({
    queryKey: ["/shipments/files", { fileId }],
    queryFn: () =>
      isDemo
        ? Promise.resolve(mockDataProcessingData.data[0] as ShipmentsFileRecord)
        : getShipmentsFile(fileId),
  });
}
