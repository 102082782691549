import Joyride, { ACTIONS, CallBackProps } from "react-joyride";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteEnum } from "@/types";
import { useIsDemo } from "@/hooks";
import { useProductTour } from "../../../productTourContext/useProductTour";
import { useEffect } from "react";
import { useJoyridePageMeta } from "./useJoyridePageMeta";
import { useTranslation } from "react-i18next";

export function ProductTour() {
  const { t } = useTranslation();
  const { joyrideState, setJoyrideState } = useProductTour();
  const { joyridePageMeta, joyrideSteps, pageKeys } = useJoyridePageMeta();

  const navigate = useNavigate();
  const isDemo = useIsDemo();
  const { pathname } = useLocation();

  useEffect(() => {
    setJoyrideState({ steps: joyrideSteps });
  }, [joyrideSteps, setJoyrideState]);

  const { run, stepIndex, steps } = joyrideState;

  const handleCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;
    const routerState = { state: { index } };

    for (const route of pageKeys) {
      if (
        (pathname !== route &&
          type === "step:after" &&
          action === ACTIONS.NEXT &&
          index === joyridePageMeta[route].startIdx - 1) ||
        (action === ACTIONS.PREV &&
          index === joyridePageMeta[route].endIdx + 1 &&
          lifecycle === "complete")
      ) {
        setJoyrideState({ run: false });
        navigate(`${route}${isDemo ? "?demo=true" : ""}`, routerState);
        return;
      }
    }

    if (action === "next" && lifecycle === "complete") {
      setJoyrideState({ stepIndex: index + 1 });
      return;
    }
    if (action === "prev" && lifecycle === "complete") {
      setJoyrideState({ stepIndex: index - 1 });
      return;
    }
    if (type === "tour:end" || action === ACTIONS.CLOSE) {
      setJoyrideState({ run: false, stepIndex: 0, tourActive: false });
      navigate(`${RouteEnum.DASHBOARD}${isDemo ? "?demo=true" : ""}`);
      return;
    }
  };

  return (
    <div>
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        disableScrolling
        showProgress
        scrollOffset={88}
        scrollDuration={0}
        showSkipButton
        locale={{
          back: t("common_prev"),
          close: t("common_close"),
          last: t("common_complete"),
          next: t("common_next"),
          open: "Open the dialog",
          skip: t("common_skipTour"),
        }}
        styles={{
          options: {
            arrowColor: "rgba(255, 255, 255, 0.9)",
            textColor: "#111827",
            overlayColor: "rgba(0,0,0,0.2)",
            primaryColor: "#0EA47A",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          },
        }}
      />
    </div>
  );
}
