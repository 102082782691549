import { MediumHeading, Subheading } from ".";
import { forwardRef, ReactNode } from "react";
import { IconFolderOpen } from "@tabler/icons-react";

interface EmptyStateProps {
  icon?: ReactNode;
  title?: string;
  description?: string;
  action?: React.ReactNode;
}

export const EmptyState = forwardRef<HTMLDivElement, EmptyStateProps>(
  (
    {
      title = "No results found",
      description = "Please check your filters and try again",
      action,
      icon,
    },
    ref,
  ) => {
    return (
      <div ref={ref} className="flex w-full justify-center">
        <div className="relative flex w-[80%] justify-center py-10 lg:w-1/3">
          <div className="flex flex-col gap-6">
            <div className="flex justify-center text-foreground/30">
              {icon ?? <IconFolderOpen className="h-28 w-28" stroke={1} />}
            </div>
            <div className="flex justify-center">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col items-center">
                  <MediumHeading className="text-center !text-lg">
                    {title}
                  </MediumHeading>
                  {description && (
                    <Subheading className="text-center">
                      {description}
                    </Subheading>
                  )}
                </div>
                {!!action && (
                  <div className="flex flex-col items-center gap-2">
                    {action}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

EmptyState.displayName = "EmptyState";
