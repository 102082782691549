import { api, authHeader } from "@/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export function useDeleteShipmentFile() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileId }: { fileId: number }) =>
      await api.delete(`/shipments/files/${fileId}`, {
        headers: { ...authHeader() },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["/shipments/files"],
        refetchType: "all",
      });
    },
  });
}
