export default {
  totalCount: 4,
  totalPages: 1,
  data: [
    {
      createdAt: "2024-02-23T00:00:00",
      name: "pending_file.xlsx",
      completenessStatus: "PENDING",
      processingStatus: "PENDING",
      id: 1,
      hasReport: false,
      successfulShipments: 100,
      processedShipments: 100,
      totalShipments: 100,
      errorCounts: [
        { column: ["transport_mode"], count: 1 },
        { column: ["reference_date"], count: 0 },
        { column: ["shipper_country"], count: 0 },
        { column: ["consignee_country"], count: 0 },
        {
          column: [
            "shipper_address",
            "shipper_city",
            "shipper_postal_code",
            "shipper_iata_code",
            "shipper_locode",
          ],
          count: 3,
        },
        {
          column: [
            "consignee_address",
            "consignee_city",
            "consignee_postal_code",
            "consignee_iata_code",
            "consignee_locode",
          ],
          count: 3,
        },
        { column: ["gross_weight_ton", "gross_weight_kg"], count: 0 },
      ],
    },
    {
      createdAt: "2024-02-23T00:00:00",
      name: "pending_file.xlsx",
      completenessStatus: "PENDING",
      processingStatus: "INITIAL_CHECK_SUCCESS",
      id: 2,
      hasReport: false,
      successfulShipments: 100,
      processedShipments: 100,
      totalShipments: 100,
      errorCounts: [
        { column: ["transport_mode"], count: 1 },
        { column: ["reference_date"], count: 0 },
        { column: ["shipper_country"], count: 0 },
        { column: ["consignee_country"], count: 0 },
        {
          column: [
            "shipper_address",
            "shipper_city",
            "shipper_postal_code",
            "shipper_iata_code",
            "shipper_locode",
          ],
          count: 3,
        },
        {
          column: [
            "consignee_address",
            "consignee_city",
            "consignee_postal_code",
            "consignee_iata_code",
            "consignee_locode",
          ],
          count: 3,
        },
        { column: ["gross_weight_ton", "gross_weight_kg"], count: 0 },
      ],
    },
    {
      createdAt: "2024-02-23T00:00:00",
      name: "pending_file.xlsx",
      completenessStatus: "PENDING",
      processingStatus: "SHIPMENT_PARSING_SUCCESS",
      id: 3,
      hasReport: false,
      successfulShipments: 100,
      processedShipments: 100,
      totalShipments: 100,
      errorCounts: [
        { column: ["transport_mode"], count: 1 },
        { column: ["reference_date"], count: 0 },
        { column: ["shipper_country"], count: 0 },
        { column: ["consignee_country"], count: 0 },
        {
          column: [
            "shipper_address",
            "shipper_city",
            "shipper_postal_code",
            "shipper_iata_code",
            "shipper_locode",
          ],
          count: 3,
        },
        {
          column: [
            "consignee_address",
            "consignee_city",
            "consignee_postal_code",
            "consignee_iata_code",
            "consignee_locode",
          ],
          count: 3,
        },
        { column: ["gross_weight_ton", "gross_weight_kg"], count: 0 },
      ],
    },
    {
      createdAt: "2024-02-23T00:00:00",
      name: "pending_file.xlsx",
      completenessStatus: "PENDING",
      processingStatus: "EMISSION_CALCULATION_SUCCESS",
      id: 4,
      hasReport: false,
      successfulShipments: 100,
      processedShipments: 100,
      totalShipments: 100,
      errorCounts: [
        { column: ["transport_mode"], count: 1 },
        { column: ["reference_date"], count: 0 },
        { column: ["shipper_country"], count: 0 },
        { column: ["consignee_country"], count: 0 },
        {
          column: [
            "shipper_address",
            "shipper_city",
            "shipper_postal_code",
            "shipper_iata_code",
            "shipper_locode",
          ],
          count: 3,
        },
        {
          column: [
            "consignee_address",
            "consignee_city",
            "consignee_postal_code",
            "consignee_iata_code",
            "consignee_locode",
          ],
          count: 3,
        },
        { column: ["gross_weight_ton", "gross_weight_kg"], count: 0 },
      ],
    },
    // {
    //   createdAt: "2024-02-25T00:00:00",
    //   name: "missing_data_points.xlsx",
    //   completenessStatus: "PARTIAL",
    //   processingStatus: "DATA_IMPORT_SUCCESS",
    //   processedShipments: 75,
    //   totalShipments: 500,
    //   reportUrl: "#",
    // },
    {
      createdAt: "2024-02-24T00:00:00",
      name: "working_file.xlsx",
      completenessStatus: "SUCCESSFUL",
      processingStatus: "DATA_IMPORT_SUCCESS",
      processedShipments: 500,
      totalShipments: 500,
      reportUrl: "#",
      id: 5,
      hasReport: false,
      successfulShipments: 100,
      errorCounts: [
        { column: ["transport_mode"], count: 1 },
        { column: ["reference_date"], count: 0 },
        { column: ["shipper_country"], count: 0 },
        { column: ["consignee_country"], count: 0 },
        {
          column: [
            "shipper_address",
            "shipper_city",
            "shipper_postal_code",
            "shipper_iata_code",
            "shipper_locode",
          ],
          count: 3,
        },
        {
          column: [
            "consignee_address",
            "consignee_city",
            "consignee_postal_code",
            "consignee_iata_code",
            "consignee_locode",
          ],
          count: 3,
        },
        { column: ["gross_weight_ton", "gross_weight_kg"], count: 0 },
      ],
    },
    {
      createdAt: "2024-02-26T00:00:00",
      name: "missing_mandatory_column.xlsx",
      completenessStatus: "FAILED",
      processingStatus: "INITIAL_CHECK_FAILED",
      processedShipments: 0,
      totalShipments: 500,
      reportUrl: "#",
      id: 6,
      hasReport: false,
      successfulShipments: 100,
      errorCounts: [
        { column: ["transport_mode"], count: 1 },
        { column: ["reference_date"], count: 0 },
        { column: ["shipper_country"], count: 0 },
        { column: ["consignee_country"], count: 0 },
        {
          column: [
            "shipper_address",
            "shipper_city",
            "shipper_postal_code",
            "shipper_iata_code",
            "shipper_locode",
          ],
          count: 3,
        },
        {
          column: [
            "consignee_address",
            "consignee_city",
            "consignee_postal_code",
            "consignee_iata_code",
            "consignee_locode",
          ],
          count: 3,
        },
        { column: ["gross_weight_ton", "gross_weight_kg"], count: 0 },
      ],
    },
    {
      createdAt: "2024-02-26T00:00:00",
      name: "missing_mandatory_column.xlsx",
      completenessStatus: "FAILED",
      processingStatus: "SHIPMENTS_PARSING_FAILED",
      processedShipments: 0,
      totalShipments: 500,
      reportUrl: "#",
      id: 7,
      hasReport: false,
      successfulShipments: 100,
      errorCounts: [
        { column: ["transport_mode"], count: 1 },
        { column: ["reference_date"], count: 0 },
        { column: ["shipper_country"], count: 0 },
        { column: ["consignee_country"], count: 0 },
        {
          column: [
            "shipper_address",
            "shipper_city",
            "shipper_postal_code",
            "shipper_iata_code",
            "shipper_locode",
          ],
          count: 3,
        },
        {
          column: [
            "consignee_address",
            "consignee_city",
            "consignee_postal_code",
            "consignee_iata_code",
            "consignee_locode",
          ],
          count: 3,
        },
        { column: ["gross_weight_ton", "gross_weight_kg"], count: 0 },
      ],
    },
    {
      createdAt: "2024-02-26T00:00:00",
      name: "missing_mandatory_column.xlsx",
      completenessStatus: "FAILED",
      processingStatus: "EMISSION_CALCULATION_FAILED",
      processedShipments: 0,
      totalShipments: 500,
      reportUrl: "#",
      id: 8,
      hasReport: false,
      successfulShipments: 100,
      errorCounts: [
        { column: ["transport_mode"], count: 1 },
        { column: ["reference_date"], count: 0 },
        { column: ["shipper_country"], count: 0 },
        { column: ["consignee_country"], count: 0 },
        {
          column: [
            "shipper_address",
            "shipper_city",
            "shipper_postal_code",
            "shipper_iata_code",
            "shipper_locode",
          ],
          count: 3,
        },
        {
          column: [
            "consignee_address",
            "consignee_city",
            "consignee_postal_code",
            "consignee_iata_code",
            "consignee_locode",
          ],
          count: 3,
        },
        { column: ["gross_weight_ton", "gross_weight_kg"], count: 0 },
      ],
    },
    {
      createdAt: "2024-02-26T00:00:00",
      name: "missing_mandatory_column.xlsx",
      completenessStatus: "FAILED",
      processingStatus: "DATA_IMPORT_FAILED",
      processedShipments: 0,
      totalShipments: 500,
      reportUrl: "#",
      id: 9,
      hasReport: false,
      successfulShipments: 100,
      errorCounts: [
        { column: ["transport_mode"], count: 1 },
        { column: ["reference_date"], count: 0 },
        { column: ["shipper_country"], count: 0 },
        { column: ["consignee_country"], count: 0 },
        {
          column: [
            "shipper_address",
            "shipper_city",
            "shipper_postal_code",
            "shipper_iata_code",
            "shipper_locode",
          ],
          count: 3,
        },
        {
          column: [
            "consignee_address",
            "consignee_city",
            "consignee_postal_code",
            "consignee_iata_code",
            "consignee_locode",
          ],
          count: 3,
        },
        { column: ["gross_weight_ton", "gross_weight_kg"], count: 0 },
      ],
    },
  ],
};
