import { createContext, useContext } from "react";

export const StepContext = createContext(
  {} as {
    step: 1 | 2 | 3 | 4;
    setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4>>;
  },
);
export function useStep() {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error("useStep must be used within a StepContextProvider");
  }
  return context;
}
