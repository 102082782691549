import { MainContentLayout } from "@/components/layouts";
import { Outlet } from "react-router-dom";

export function HomePage() {
  return (
    <>
      <MainContentLayout>
        <Outlet />
      </MainContentLayout>
    </>
  );
}
