import { useMutation /* useQueryClient */ } from "@tanstack/react-query";
import { ErrorResponse, api, authHeader } from "@/api";

export interface UploadFileResponse {
  status: "SUCCESS" | "PARTIAL_SUCCESS" | "FAILED";
  message: string;
  fileId: number;
  totalRows?: number;
  validRows?: number;
  invalidRows?: number;
  missingColumns?: string[];
}

const uploadFile = async ({ file, setUploadProgress }: MutationVariables) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await api.post("/data-import/upload/shipment", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total !== undefined) {
        setUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        );
      }
    },
  });
  return response.data as UploadFileResponse;
};

export interface MutationVariables {
  file: File;
  setUploadProgress: React.Dispatch<React.SetStateAction<number>>;
}

interface useUploadShipmentFileProps {
  onSuccess?: () => void;
}
export const useUploadShipmentFile = ({
  onSuccess,
}: useUploadShipmentFileProps = {}) => {
  return useMutation<UploadFileResponse, ErrorResponse, MutationVariables>({
    mutationFn: (mutationVariables: MutationVariables) =>
      uploadFile(mutationVariables),
    onSuccess: () => {
      onSuccess?.();
    },
  });
};
