import { CompletenessStatus, useGetShipmentsFile } from "@/hooks/queries";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Cards } from "./Cards";
import { InvalidShipmentsAlert } from "./InvalidShipmentsAlert";
import { FixInvalidShipmentsSection } from "./FixInvalidShipmentsSection";
import { CleanedDataSection } from "./CleanedDataSection";
import { UploadTipsSection } from "./UploadTipsSection";
import { handleAuthDownload } from "@/lib/utils";
import { useCallback } from "react";

export function Main() {
  const { t } = useTranslation();
  const { fileId } = useParams();
  const { data, isLoading, isError } = useGetShipmentsFile({
    fileId: Number(fileId),
  });

  const { processingReportFileName, name, hasReport, completenessStatus } =
    data;
  const downloadInvalidShipments = useCallback(() => {
    handleAuthDownload({
      url: `/reports/invalid-file-report?fileId=${fileId}`,
      fileName: processingReportFileName,
      originalFileName: name,
      documentType: "invalid_shipments",
    }).catch((error) => {
      console.error("Error downloading invalid shipments:", error);
    });
  }, [fileId, name, processingReportFileName]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  const { totalShipments, successfulShipments, dataScore, errorCounts } = data;

  const invalidShipments =
    typeof successfulShipments === "number"
      ? totalShipments - successfulShipments
      : null;

  const cardsData = [
    {
      title: "Valid shipments",
      translatedTitle: t("common_validShipments"),
      value: successfulShipments,
      percentage:
        typeof successfulShipments === "number"
          ? (successfulShipments / totalShipments) * 100
          : null,
    },
    {
      title: "Invalid shipments",
      translatedTitle: t("common_invalidShipments"),
      value: invalidShipments,
      percentage:
        typeof invalidShipments === "number"
          ? (invalidShipments / totalShipments) * 100
          : null,
    },
  ];

  const filteredErrorCounts = errorCounts
    ? errorCounts.filter((ec) => ec.count > 0)
    : [];

  return (
    <div className="grid w-full grid-cols-12 gap-6 py-3">
      <Cards
        cardsData={cardsData}
        dataScore={dataScore}
        totalShipments={totalShipments}
      />

      {/* Info Alert */}
      {(invalidShipments || 0) > 0 && (
        <InvalidShipmentsAlert
          invalidShipments={invalidShipments}
          downloadInvalidShipments={hasReport ? downloadInvalidShipments : null}
        />
      )}

      {/* Fix invalid shipments */}
      {filteredErrorCounts.length > 0 && (
        <FixInvalidShipmentsSection
          filteredErrorCounts={filteredErrorCounts}
          downloadInvalidShipments={hasReport ? downloadInvalidShipments : null}
        />
      )}

      {completenessStatus !== CompletenessStatus.Failed && (
        <>
          {/* We cleaned your data */}
          <CleanedDataSection />
          {/* Tips for you future uploads */}
          <UploadTipsSection />
        </>
      )}
    </div>
  );
}
