import { useIsDemo } from "@/hooks";
import useSetState from "@/hooks/useSetState";
import { RouteEnum } from "@/types";
import { createContext, useCallback, useMemo } from "react";
import { Step } from "react-joyride";
import { useLocation, useNavigate } from "react-router-dom";

interface JoyrideState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
}

export const ProductTourContext = createContext(
  {} as {
    joyrideState: JoyrideState;
    setJoyrideState: (
      patch:
        | Partial<JoyrideState>
        | ((prevState: JoyrideState) => Partial<JoyrideState>),
    ) => void;
    startProductTour: () => void;
  },
);

export function ProductTourProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [joyrideState, setJoyrideState] = useSetState<JoyrideState>({
    run: false,
    stepIndex: 0,
    steps: [],
    tourActive: false,
  });

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDemo = useIsDemo();

  const startProductTour = useCallback(() => {
    if (pathname !== (RouteEnum.DASHBOARD as string)) {
      navigate(`${RouteEnum.DASHBOARD}${isDemo ? "?demo=true" : ""}`);
    }
    setJoyrideState({ run: true, tourActive: true, stepIndex: 0 });
  }, [isDemo, navigate, pathname, setJoyrideState]);

  const contextValue = useMemo(
    () => ({
      joyrideState,
      setJoyrideState,
      startProductTour,
    }),
    [joyrideState, setJoyrideState, startProductTour],
  );

  return (
    <ProductTourContext.Provider value={contextValue}>
      {children}
    </ProductTourContext.Provider>
  );
}
