import { enGB, de, fr } from "date-fns/locale";
import { getLocale } from "@/i18next/i18n";

/**
 * Returns the appropriate date-fns locale based on the resolved language from i18next.
 *
 * @return {Locale} The date-fns locale object corresponding to the resolved language.
 * - If the resolved language is "de", returns the German locale.
 * - If the resolved language is "fr", returns the French locale.
 * - Otherwise, returns the English (Great Britain) locale.
 */
export function getDateLocale() {
  const locale = getLocale();
  switch (locale) {
    case "de":
      return de;
    case "fr":
      return fr;
    default:
      return enGB;
  }
}
