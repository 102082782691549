import { Button, buttonVariants } from "@/components/ui";
import { UploadFileResponse, useStartFileProcessing } from "@/hooks/mutations";
import { cn } from "@/lib/utils";
import { IconArrowRight, IconDownload } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ActionButtonsProps {
  status: UploadFileResponse["status"];
  onProceed: () => void;
  onReset: () => void;
  fileId: number;
}

export function ActionButtons({
  status,
  onProceed,
  onReset,
  fileId,
}: ActionButtonsProps) {
  const { t } = useTranslation();

  const { mutate: startFileProcessing } = useStartFileProcessing({
    onSuccess: () => onProceed(),
  });

  if (status === "FAILED") {
    return (
      <>
        <Link
          to="https://nxtlogmdevsa.blob.core.windows.net/public-files/input_file_example_1_row.xlsx"
          target="_blank"
          className={cn(
            buttonVariants({ variant: "outline" }),
            "border-none bg-background font-normal",
          )}
          rel="noreferrer"
          download
        >
          <IconDownload className="mr-2 h-3.5 w-3.5 text-primary" stroke={1} />
          <span className="whitespace-nowrap">
            {t("common_downloadSampleData", "Download sample data")}
          </span>
        </Link>
        <Button onClick={onReset}>
          <span className="mr-2">{t("common_startOver", "Start over")}</span>
        </Button>
      </>
    );
  }

  return (
    <>
      {status === "PARTIAL_SUCCESS" && (
        <Button variant="outline" onClick={onReset}>
          <span className="mr-2">{t("common_cancel", "Start over")}</span>
        </Button>
      )}
      <Button
        onClick={
          status === "PARTIAL_SUCCESS"
            ? () => startFileProcessing({ fileId })
            : onProceed
        }
      >
        <span className="mr-2">{t("common_proceed", "Proceed")}</span>
        <IconArrowRight className="h-4 w-4" stroke={1} />
      </Button>
    </>
  );
}
