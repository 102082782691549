import { ErrorResponse } from "@/api";
import { InfoAlert } from "..";
import { motion } from "framer-motion";
import { Button } from "@/components/ui";
import { useTranslation } from "react-i18next";
import { IconAlertTriangle } from "@tabler/icons-react";

function UploadFailureButtons({ onReset }: { onReset: () => void }) {
  const { t } = useTranslation();
  return (
    <motion.div layout className="flex w-full justify-end gap-2">
      <Button onClick={onReset}>
        <span className="mr-2">{t("common_startOver", "Start over")}</span>
      </Button>
    </motion.div>
  );
}

interface UploadErrorResponseProps {
  error: ErrorResponse | null;
  onReset: () => void;
}

export function UploadErrorResponse({
  error,
  onReset,
}: UploadErrorResponseProps) {
  if (!error) return null;
  const { message } = error;
  return (
    <>
      <InfoAlert
        title={"Failed"}
        IconComponent={IconAlertTriangle}
        variant="nxtLogRed"
      >
        <p>{message || "Something went wrong"}</p>
      </InfoAlert>
      <UploadFailureButtons onReset={onReset} />
    </>
  );
}
