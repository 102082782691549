import { keycloakInstance } from "./keycloakInstance";
import { AuthContext } from "./useAuth";
import { useEffect, useMemo, useState } from "react";
import { fakeKeyCloakInstance } from "./fakeKeycloakInstance";
import { initialDemoProfile } from "./initialDemoProfile";

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const isDemo = searchParams.get("demo") === "true";
  const [profile, setProfile] = useState(initialDemoProfile);

  const { authenticated } = keycloakInstance;

  useEffect(() => {
    if (!isDemo) {
      if (authenticated) {
        keycloakInstance
          .loadUserProfile()
          .then(({ firstName, lastName, username }) => {
            setProfile({
              firstName: firstName || "",
              lastName: lastName || "",
              username: username || "",
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setProfile(initialDemoProfile);
      }
    }
  }, [authenticated, isDemo]);

  const contextValue = useMemo(
    () => ({
      keycloak: isDemo ? fakeKeyCloakInstance : keycloakInstance,
      profile,
    }),
    [isDemo, profile],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
