import { createContext, useContext } from "react";
import Keycloak from "keycloak-js";
import { FakeKeyCloak } from "./fakeKeycloakInstance";

export const AuthContext = createContext(
  {} as {
    keycloak: Keycloak | FakeKeyCloak;
    profile?: {
      firstName: string;
      lastName: string;
      username: string;
    };
  },
);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
