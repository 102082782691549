import { Alert, AlertTitle, AlertDescription, type AlertVariants } from "../ui";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { Icon, IconInfoCircle, IconProps } from "@tabler/icons-react";

export interface InfoAlertProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  variant?: AlertVariants;
  children?: string | React.ReactNode;
  IconComponent?: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
}

export function InfoAlert({
  title,
  children,
  IconComponent = IconInfoCircle,
  variant = "nxtLogGreen",
  ...props
}: InfoAlertProps) {
  return (
    <Alert variant={variant} {...props}>
      <IconComponent className="h-5 w-5" stroke={1} />
      {title && <AlertTitle className="font-bold">{title}</AlertTitle>}
      {children && <AlertDescription>{children}</AlertDescription>}
    </Alert>
  );
}
