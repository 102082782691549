import { useEffect, useState } from "react";

type SemiCircularProgressBarProps = {
  progress: number;
  size?: number;
  strokeWidth?: number;
  color?: string;
  showPercentage?: boolean;
  percentageSmall?: boolean;
  animationDuration?: number;
  colorBreakpoint?: number;
  swapColors?: boolean;
};

function SemiCircularProgressBar({
  progress,
  size = 26,
  strokeWidth,
  showPercentage = false,
  animationDuration = 800,
  percentageSmall = true,
  colorBreakpoint = 50,
  swapColors = false,
}: SemiCircularProgressBarProps) {
  const appliedStrokeWidth = strokeWidth ? strokeWidth : size / 9;
  const radius = (size - appliedStrokeWidth) / 2;
  const circumference = radius * Math.PI * 1.5; // 75% of a full circle
  const [animatedProgress, setAnimatedProgress] = useState(0);
  // const strokeDashoffset = circumference - (progress / 100) * circumference;

  const startAngle = 225; // 180 + 45 degrees
  const endAngle = 495; // 180 + 45 + 270 degrees

  const x1 = size / 2 + radius * Math.cos((startAngle * Math.PI) / 180);
  const y1 = size / 2 + radius * Math.sin((startAngle * Math.PI) / 180);
  const x2 = size / 2 + radius * Math.cos((endAngle * Math.PI) / 180);
  const y2 = size / 2 + radius * Math.sin((endAngle * Math.PI) / 180);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimatedProgress(progress);
    }, 50); // Small delay to ensure the component has mounted

    return () => clearTimeout(timer);
  }, [progress]);

  const strokeDashoffset =
    circumference - (animatedProgress / 100) * circumference;

  const lowColor = swapColors ? `hsl(var(--primary))` : `var(--yellow-500)`;
  const highColor = swapColors ? `var(--yellow-500)` : `hsl(var(--primary))`;

  return (
    <div className="relative inline-flex items-center justify-center">
      <svg
        className="-rotate-90"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
      >
        <path
          d={`M${x1},${y1} A${radius},${radius} 0 1,1 ${x2},${y2}`}
          fill="none"
          stroke="hsl(var(--foreground) / 0.05)"
          strokeWidth={appliedStrokeWidth}
          strokeLinecap="round"
        />
        <path
          d={`M${x1},${y1} A${radius},${radius} 0 1,1 ${x2},${y2}`}
          fill="none"
          stroke={progress < colorBreakpoint ? lowColor : highColor}
          strokeWidth={appliedStrokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          style={{
            transition: `stroke-dashoffset ${animationDuration}ms ease`,
          }}
        />
      </svg>
      <div className={"absolute"}>
        <span style={{ fontSize: size / 2.7 }} className="font-bold">
          {Math.round(progress)}
        </span>
        {showPercentage && (
          <span
            className="font-light"
            style={{
              fontSize: percentageSmall ? size / 5.5 : size / 3.5,
              lineHeight: 0,
            }}
          >
            %
          </span>
        )}
      </div>
    </div>
  );
}

export default SemiCircularProgressBar;
