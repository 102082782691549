import { LeftBannerPortal } from "@/components/layouts/NoNavLayout/components/LeftBannerPortal";
import { Link } from "react-router-dom";

export function LegalPageBannerTitle({ children }: { children: string }) {
  const leftBannerContent = (
    <>
      <div className="text-2xl font-semibold">
        <p className="text-balance pb-5">{children}</p>
      </div>
      <Link
        to="/"
        className="pl-6 text-sm text-emerald-100 hover:text-emerald-200 hover:underline"
      >
        {"< Back to Home Page"}
      </Link>
    </>
  );

  return <LeftBannerPortal>{leftBannerContent}</LeftBannerPortal>;
}
