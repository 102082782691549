import {
  defaultDateRange,
  getDateRangeOptions,
  RequiredDateRange,
} from "@/components/layouts/NavigationLayout/dateRangeContext";
import { useCallback, useState } from "react";

const dateRangeOptions = getDateRangeOptions();

export function useMonthRangePicker() {
  const [dateRange, setDateRange] = useState<RequiredDateRange>(
    () => defaultDateRange,
  );

  const handlePresetClick = useCallback((label: string) => {
    const selectedOption = dateRangeOptions.find((o) => o.label === label);
    if (selectedOption) {
      setDateRange(selectedOption.value);
    }
  }, []);

  const onApply = useCallback(
    ({ from, to }: RequiredDateRange) => setDateRange({ from, to }),
    [setDateRange],
  );

  return {
    dateRange,
    handlePresetClick,
    onApply,
  };
}
