import {
  format,
  subMonths,
  startOfQuarter,
  endOfQuarter,
  endOfYear,
  startOfYear,
  subYears,
  endOfDay,
} from "date-fns";
import { RequiredDateRange } from "./DateRangeContextProvider2";
import { formatDateRange } from "@/lib/utils";

function getThisQuarter(): RequiredDateRange {
  const today = new Date();
  const startOfCurrentQuarter = startOfQuarter(today);

  return {
    from: startOfCurrentQuarter,
    to: endOfDay(today),
  };
}

function getLastQuarter(): RequiredDateRange {
  const today = new Date();
  const startOfLastQuarter = startOfQuarter(subMonths(today, 3));
  const endOfLastQuarter = endOfQuarter(startOfLastQuarter);

  return {
    from: startOfLastQuarter,
    to: endOfLastQuarter,
  };
}

function getThisYear(): RequiredDateRange {
  const today = new Date();
  const startOfCurrentYear = startOfYear(today);

  return {
    from: startOfCurrentYear,
    to: endOfDay(today),
  };
}

function getLastYear(): RequiredDateRange {
  const today = new Date();
  const startOfLastYear = startOfYear(subYears(today, 1));
  const endOfLastYear = endOfYear(subYears(today, 1));

  return {
    from: startOfLastYear,
    to: endOfLastYear,
  };
}

function getTwoYearsAgo(): RequiredDateRange {
  const today = new Date();
  const startOfTwoYearsAgo = startOfYear(subYears(today, 2));
  const endOfTwoYearsAgo = endOfYear(subYears(today, 2));

  return {
    from: startOfTwoYearsAgo,
    to: endOfTwoYearsAgo,
  };
}

function getLastThreeYears(): RequiredDateRange {
  const today = new Date();
  const startOfLastThreeYears = startOfYear(subYears(today, 3));

  return {
    from: startOfLastThreeYears,
    to: endOfDay(today),
  };
}

// Get the year from 2 years back in the format YYYY
function getTwoYearsAgoYearString() {
  const today = new Date();
  const startOfTwoYearsAgo = startOfYear(subYears(today, 2));
  const year = format(startOfTwoYearsAgo, "yyyy");
  return year;
}

function getValue(label: string) {
  switch (label) {
    case "This Quarter":
      return getThisQuarter();
    case "Last Quarter":
      return getLastQuarter();
    case "This Year":
      return getThisYear();
    case "Last Year":
      return getLastYear();
    case `${getTwoYearsAgoYearString()}`:
      return getTwoYearsAgo();
    default:
      return getLastThreeYears();
  }
}

function getOptions({ label, tLabel }: { label: string; tLabel: string }) {
  const value = getValue(label);
  return {
    label,
    tLabel,
    value,
    stringValue: formatDateRange(value),
  };
}

const optionLabels = [
  { label: "This Quarter", tLabel: "common_thisQuarter" },
  { label: "Last Quarter", tLabel: "common_lastQuarter" },
  { label: "This Year", tLabel: "common_thisYear" },
  { label: "Last Year", tLabel: "common_lastYear" },
  {
    label: `${getTwoYearsAgoYearString()}`,
    tLabel: "",
  },
  { label: "Last 3 Years", tLabel: "common_lastThreeYears" },
];

export const getDateRangeOptions = () =>
  optionLabels.map(({ label, tLabel }) => getOptions({ label, tLabel }));
