import { AnimatePresence, Variants, motion } from "framer-motion";

interface GradualSpacingProps {
  children: string;
  duration?: number;
  delayMultiple?: number;
  framerProps?: Variants;
  className?: string;
}

export default function GradualSpacing({
  children,
  duration = 1,
  delayMultiple = 0.02,
  framerProps = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  },
  className,
}: GradualSpacingProps) {
  return (
    <h1 className={className}>
      <AnimatePresence>
        {children.split("").map((char, i) => (
          <motion.span
            key={i}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={framerProps}
            transition={{ duration, delay: i * delayMultiple }}
          >
            {char === " " ? <span> </span> : char}
          </motion.span>
        ))}
      </AnimatePresence>
    </h1>
  );
}
