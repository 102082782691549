import { QueryErrorBoundary } from "@/components/shared";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
  ScrollArea,
} from "@/components/ui";
import { Suspense } from "react";
import { Loader } from "@/components/shared/loaders";
import { TermsAndConditionsContent } from ".";
import { useTranslation } from "react-i18next";

export function TermsAndConditionsDialog({
  trigger,
}: {
  trigger?: React.ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <Dialog>
      <DialogTrigger asChild>
        {trigger || (
          <Button
            className="whitespace-nowrap px-0.5 font-light"
            variant="link"
          >
            {t("common_termsAndConditions")}
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="flex h-[95vh] max-w-[95vw] flex-col justify-between xl:max-w-[70vw]">
        <ScrollArea className="h-full">
          <QueryErrorBoundary>
            <Suspense
              fallback={
                <div className="grid h-full w-full place-content-center">
                  <Loader className="h-1/3" />
                </div>
              }
            >
              <TermsAndConditionsContent />
            </Suspense>
          </QueryErrorBoundary>
        </ScrollArea>
        <DialogFooter>
          <DialogClose asChild>
            <Button>Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
