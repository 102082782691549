import { DEMO_USER_ID } from "@/lib/constants";
import { KeycloakLoginOptions } from "keycloak-js";
import { initialDemoProfile } from "./initialDemoProfile";

export const fakeKeyCloakInstance = {
  authenticated: false,
  token: "",
  createLoginUrl: function ({ redirectUri }: { redirectUri: string }) {
    this.authenticated = true;
    return redirectUri;
  },
  createLogoutUrl: function ({ redirectUri }: { redirectUri: string }) {
    this.authenticated = false;
    return redirectUri;
  },
  loadUserProfile: async function () {
    return Promise.resolve(initialDemoProfile);
  },
  login(_options?: KeycloakLoginOptions) {
    return null;
  },
  updateToken: async function () {
    return Promise.resolve(false);
  },
  clearToken: function () {
    this.authenticated = false;
  },
  subject: DEMO_USER_ID,
};

export type FakeKeyCloak = typeof fakeKeyCloakInstance;
