import { EmptyState } from "@/components/shared";
import { UploadDialogDrawer } from "./UploadDialogDrawer";
import { useState } from "react";
import { Link } from "react-router-dom";
import { DATA_TEMPLATE_URL } from "@/lib/constants";
import { buttonVariants } from "@/components/ui";
import { useTranslation } from "react-i18next";
import { upperFirst } from "@/lib/utils";
import { IconDownload } from "@tabler/icons-react";

export function EmptyStateComponent() {
  const { t } = useTranslation();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(() => false);
  return (
    <EmptyState
      title={t("titles_noDataImports")}
      description={t("descriptions_noDataImports")}
      action={
        <>
          <Link
            to={DATA_TEMPLATE_URL}
            target="_blank"
            className={buttonVariants({ variant: "outline" })}
            rel="noreferrer"
            download
          >
            <IconDownload
              className="mr-2 h-3.5 w-3.5 text-primary"
              stroke={1}
            />
            <span className="whitespace-nowrap">
              {upperFirst(t("common_downloadImportTemplate"))}
            </span>
          </Link>
          <UploadDialogDrawer
            openState={uploadDialogOpen}
            setOpenState={setUploadDialogOpen}
          />
        </>
      }
    />
  );
}
