import { Skeleton } from "@/components/ui";
import { cn } from "@/lib/utils";

interface TableLoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  rows?: number;
}

export function TableLoader({
  className,
  rows = 10,
  ...props
}: TableLoaderProps) {
  return (
    <div className={cn("divide-y p-3 dark:bg-zinc-800", className)} {...props}>
      <>
        <div className="w-full bg-gray-100 dark:bg-zinc-900">
          <div className="flex h-[65px] w-full items-center gap-x-10 px-2 blur-[1px]">
            <Skeleton className="h-[24px] flex-1" />
            <Skeleton className="h-[24px] flex-1" />
            <Skeleton className="h-[24px] flex-1" />
            <Skeleton className="h-[24px] flex-1" />
          </div>
        </div>
        {Array.from({ length: rows }, (_, i) => (
          <div key={i} className="w-full">
            <div className="flex h-[65px] w-full items-center gap-x-10 px-2 blur-[1px]">
              <Skeleton className="h-[24px] flex-1" />
              <Skeleton className="h-[24px] flex-1" />
              <Skeleton className="h-[24px] flex-1" />
              <Skeleton className="h-[24px] flex-1" />
            </div>
          </div>
        ))}
      </>
    </div>
  );
}
