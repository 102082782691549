import { BaseIcon, IconProps } from ".";

export function LucideLoaderCircle(props: IconProps) {
  return (
    <BaseIcon {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 12a9 9 0 1 1-6.219-8.56"
      ></path>
    </BaseIcon>
  );
}
