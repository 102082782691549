import { getLocale, SupportedLngs } from "@/i18next/i18n";
import { NumericFormat } from "react-number-format";

const localeFormats: Record<
  SupportedLngs,
  { thousandSeparator: string; decimalSeparator: string }
> = {
  en: { thousandSeparator: ",", decimalSeparator: "." },
  de: { thousandSeparator: ".", decimalSeparator: "," },
  fr: { thousandSeparator: " ", decimalSeparator: "," },
};

export function FormatNumber({
  children,
  suffix,
  prefix,
  decimalScale = 2,
}: {
  children: string | number;
  suffix?: string;
  prefix?: string;
  decimalScale?: number;
}) {
  const locale = getLocale();
  return (
    <NumericFormat
      value={children}
      className="white-space-nowrap"
      displayType={"text"}
      // thousandSeparator={true}
      thousandSeparator={localeFormats[locale].thousandSeparator}
      decimalSeparator={localeFormats[locale].decimalSeparator}
      suffix={suffix ? ` ${suffix}` : undefined}
      prefix={prefix ? `${prefix} ` : undefined}
      decimalScale={decimalScale}
    />
  );
}
