/**
 * Returns the initials of the given first and last names.
 *
 * @param {string} firstName - the first name
 * @param {string} lastName - the last name
 * @return {string} the initials in uppercase
 */
export const getInitials = (firstName: string, lastName: string) => {
  return `${firstName.trim()[0] || ""}${lastName.trim()[0] || ""}`.toUpperCase();
};
