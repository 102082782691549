import { Step } from "react-joyride";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getPageSteps } from "./getPageSteps";

const findStartIndex = ({
  page,
  pages,
  steps,
}: {
  page: string;
  pages: string[];
  steps: Step[][];
}) => {
  const indexOfPrevPage = pages.indexOf(page) - 1;
  if (indexOfPrevPage < 0) {
    return 0;
  }
  let pageStartIndex = 0;
  for (let i = 0; i <= indexOfPrevPage; i++) {
    pageStartIndex += steps[i].length;
  }
  return pageStartIndex;
};

export const useJoyridePageMeta = () => {
  const { t } = useTranslation();
  const pageSteps = useMemo(() => getPageSteps(t), [t]);
  const pageKeys = useMemo(() => Object.keys(pageSteps), [pageSteps]);
  const pageValues = useMemo(() => Object.values(pageSteps), [pageSteps]);

  const joyrideSteps = useMemo(
    () =>
      Object.values(pageSteps).reduce(
        (acc, currentValue) => acc.concat(currentValue),
        [],
      ),
    [pageSteps],
  );

  const joyridePageMeta = useMemo(
    () =>
      pageKeys.reduce(
        (
          acc: Record<string, { startIdx: number; endIdx: number }>,
          currentValue: string,
          currentIndex: number,
        ) => {
          return {
            ...acc,
            [currentValue]: {
              startIdx: findStartIndex({
                page: currentValue,
                pages: pageKeys,
                steps: pageValues,
              }),
              endIdx:
                findStartIndex({
                  page: currentValue,
                  pages: pageKeys,
                  steps: pageValues,
                }) +
                pageValues[currentIndex].length -
                1,
            },
          };
        },
        {} as Record<string, { startIdx: number; endIdx: number }>,
      ),
    [pageKeys, pageValues],
  );

  return useMemo(
    () => ({
      joyridePageMeta,
      joyrideSteps,
      pageKeys,
    }),
    [joyridePageMeta, joyrideSteps, pageKeys],
  );
};
