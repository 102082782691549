import { cn } from "@/lib/utils";

export function Loader({ className }: { className?: string }) {
  return (
    <span
      style={{
        maskSize: "100%",
        maskRepeat: "no-repeat",
        maskPosition: "center",
        maskImage:
          "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyBzdHlsZT0nc2hhcGUtcmVuZGVyaW5nOiBhdXRvOycgd2lkdGg9JzIwMHB4JyBoZWlnaHQ9JzIwMHB4JyB2aWV3Qm94PScwIDAgMTAwIDEwMCcgcHJlc2VydmVBc3BlY3RSYXRpbz0neE1pZFlNaWQnPjxwYXRoIGZpbGw9J25vbmUnIHN0cm9rZT0nIzBhMGEwYScgc3Ryb2tlLXdpZHRoPScxMCcgc3Ryb2tlLWRhc2hhcnJheT0nMjA1LjI3MTE0MjU3ODEyNSA1MS4zMTc3ODU2NDQ1MzEyNTYnIGQ9J00yNC4zIDMwQzExLjQgMzAgNSA0My4zIDUgNTBzNi40IDIwIDE5LjMgMjBjMTkuMyAwIDMyLjEtNDAgNTEuNC00MCBDODguNiAzMCA5NSA0My4zIDk1IDUwcy02LjQgMjAtMTkuMyAyMEM1Ni40IDcwIDQzLjYgMzAgMjQuMyAzMHonIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3R5bGU9J3RyYW5zZm9ybTpzY2FsZSgwLjgpO3RyYW5zZm9ybS1vcmlnaW46NTBweCA1MHB4Jz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdzdHJva2UtZGFzaG9mZnNldCcgcmVwZWF0Q291bnQ9J2luZGVmaW5pdGUnIGR1cj0nMnMnIGtleVRpbWVzPScwOzEnIHZhbHVlcz0nMDsyNTYuNTg4OTI4MjIyNjU2MjUnPjwvYW5pbWF0ZT48L3BhdGg+PC9zdmc+)",
      }}
      className={cn(
        "inline-block aspect-square h-1/4 bg-foreground/50",
        className,
      )}
    ></span>
  );
}

// import "./loader.css";
// import { cn } from "@/lib/utils";

// export function Loader({ className }: { className?: string }) {
//   return (
//     <svg className={cn("mario-path", className)} viewBox="-1 -1 12 8">
//       <defs>
//         <path
//           id="infinite"
//           d="M5 3C4 2 3.1 1 2 1a2 2
//    0 000 4c1.1 0 2-1 3-2s1.9-2
//    3-2a2 2 0 010 4C6.9
//    5 6 4 5 3"
//         />

//         <radialGradient
//           id="sgrad"
//           gradientUnits="userSpaceOnUse"
//           cx="5"
//           cy="3"
//           r="3.4"
//         >
//           <stop offset=".25" stopColor="#d6d6d6" />
//           <stop offset=".9" stopColor="#d6d6d6" stopOpacity="0" />
//         </radialGradient>
//       </defs>

//       <use xlinkHref="#infinite" className="bottom" />
//       <path
//         className="clip-shadow"
//         d="M4.5 2.5C3.7 1.7 2.9 1 2 1m6 4c-1 0-1.7-.7-2.5-1.5"
//       />
//       <use xlinkHref="#infinite" className="rh-line" />
//       <use xlinkHref="#infinite" className="ibm-line" />
//     </svg>
//   );
// }
