import { useGetMe } from "./queries";

function getIsUploadLimitExceeded(
  uploadUsedBytes: number | null,
  uploadLimitBytes: number | null,
  fileSize?: number,
) {
  if (!fileSize) return false;
  if (!uploadLimitBytes) return false;
  return (uploadUsedBytes || 0) + fileSize > (uploadLimitBytes || 0);
}

export function useIsUploadLimitExceeded(fileSize: number | undefined) {
  const { data } = useGetMe({ enabled: true });
  if (!data) return false;
  const { uploadLimitBytes, uploadUsedBytes } = data;
  return getIsUploadLimitExceeded(uploadUsedBytes, uploadLimitBytes, fileSize);
}
