export function MonthGrid({ children }: { children: React.ReactNode }) {
  return (
    <div className="rounded-lg bg-background p-3 shadow-custom">
      <div
        className="grid w-full grid-cols-3 justify-items-center gap-y-3"
        role="grid"
      >
        {children}
      </div>
    </div>
  );
}
