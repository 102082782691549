import { UploadFileResponse } from "@/hooks/mutations";
import { useTranslation } from "react-i18next";
import { getInfoAlertProps } from "./getInfoAlertProps";
import { InfoAlert } from "../..";
import { ErrorReportButton } from "./ErrorReportButton";
import { ActionButtons } from "./ActionButtons";

interface UploadCompleteResponseProps {
  data?: UploadFileResponse;
  onUploadComplete?: () => void;
  onReset: () => void;
}

export function UploadCompleteResponse({
  data,
  onUploadComplete,
  onReset,
}: UploadCompleteResponseProps) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const { status, invalidRows, totalRows, missingColumns, fileId } = data;

  const issueDetails = (
    <>
      {invalidRows && totalRows && totalRows > 0 ? (
        <p className="mt-4 font-bold">
          {t("messages_initialCheck-invalidRows", {
            invalid: invalidRows,
            total: totalRows,
          })}
        </p>
      ) : null}
      {missingColumns && missingColumns.length > 0 ? (
        <p className="mt-4 font-bold">
          {t("messages_initialCheck-missingColumns")}:{" "}
          {missingColumns.join(", ")}
        </p>
      ) : null}
    </>
  );

  const InfoAlertProps = getInfoAlertProps(t);

  return (
    <>
      <InfoAlert {...InfoAlertProps[status]}>
        {InfoAlertProps[status].children}
        {issueDetails}
      </InfoAlert>
      <div className="flex w-full justify-end gap-2">
        <ErrorReportButton status={status} fileId={fileId} />
        <ActionButtons
          status={status}
          onProceed={() => {
            onReset();
            onUploadComplete?.();
          }}
          onReset={onReset}
          fileId={data.fileId}
        />
      </div>
    </>
  );
}
