import { Loader } from "@/components/shared/loaders";
import { Alert, AlertTitle } from "@/components/ui";
import { useTranslation } from "react-i18next";

interface UploadPendingResponseProps {
  isPending: boolean;
}

export function UploadPendingResponse({
  isPending,
}: UploadPendingResponseProps) {
  const { t } = useTranslation();
  if (!isPending) return null;
  return (
    <Alert>
      <AlertTitle>
        <div className="flex w-full items-center justify-start gap-6 text-sm font-normal text-gray-500">
          <Loader className="w-40" />
          <p>
            {t(
              "messages_pleaseWait-fileUpload",
              "Please wait, we are currently validating if all mandatory columns include valid data, this can take a moment…",
            )}
          </p>
        </div>
      </AlertTitle>
    </Alert>
  );
}
